/*-----------------------------------------------------------------------------------

    Template Name: Creasoft - software and digital agency HTML 5 template
    Author: Egens Theme
    Author https://themeforest.net/user/egenstheme/portfolio
    Support: https://www.egenslab.com/support/
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01.COMMON
    02.Header css
    03.Hero css
    04.Services css
    05.About css
    06.Feature css
    07.Project css
    08.Partner css
    09.Price plan css
    10.Testimonial css
    11.Our Team css
    12.Blog css
    13.Subscribe css
    14.Footer css
    15.Home two css
    16.Contact page css
    17.Error page css
    18.FAQ page css
    19.Service page css
    20.Project page css
    21.About page css
    22.Blog page css

-----------------------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Saira:wght@300;400;500;600;700;800&display=swap");

/*===========================
     01.COMMON CSS
===========================*/
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  outline: 0 solid;
}


body {
  height: 100%;
}

body {
  font-size: 16px;
  font-family: "Saira", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Saira", sans-serif;
  font-weight: 400;
  margin-bottom: 15px;
}

img {
  max-width: 100%;
}

a,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

p {
  margin: 0;
  color: #565656;
  line-height: 24px;
}

@media (max-width: 767px) {
  p {
    font-size: 16px;
  }
}

a,
a:hover {
  text-decoration: none;
}

input,
select,
canvas,
img,
svg,
video {
  vertical-align: middle;
}

.sec-mar-top {
  margin-top: 120px;
}

/*=======================================
 preloader start
=======================================*/
.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: #000;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@-webkit-keyframes loader {
  0%,
  10%,
  100% {
    width: 80px;
    height: 80px;
  }

  65% {
    width: 150px;
    height: 150px;
  }
}

@keyframes loader {
  0%,
  10%,
  100% {
    width: 80px;
    height: 80px;
  }

  65% {
    width: 150px;
    height: 150px;
  }
}

@-webkit-keyframes loaderBlock {
  0%,
  30% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  55% {
    background-color: #fff;
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes loaderBlock {
  0%,
  30% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  55% {
    background-color: #3fb7e2;
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@-webkit-keyframes loaderBlockInverse {
  0%,
  20% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  55% {
    background-color: #fff;
  }

  100% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}

@keyframes loaderBlockInverse {
  0%,
  20% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  55% {
    background-color: #3fb7e2;
  }

  100% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}

.preloader .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  -webkit-transform: translate(-50%, -50%) rotate(45deg) translate3d(0, 0, 0);
  transform: translate(-50%, -50%) rotate(45deg) translate3d(0, 0, 0);
  -webkit-animation: loader 1.2s infinite ease-in-out;
  animation: loader 1.2s infinite ease-in-out;
}

.preloader .loader span {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  background-color: #fff;
  -webkit-animation: loaderBlock 1.2s infinite ease-in-out both;
  animation: loaderBlock 1.2s infinite ease-in-out both;
}

.preloader .loader span:nth-child(1) {
  top: 0;
  left: 0;
}

.preloader .loader span:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation: loaderBlockInverse 1.2s infinite ease-in-out both;
  animation: loaderBlockInverse 1.2s infinite ease-in-out both;
}

.preloader .loader span:nth-child(3) {
  bottom: 0;
  left: 0;
  -webkit-animation: loaderBlockInverse 1.2s infinite ease-in-out both;
  animation: loaderBlockInverse 1.2s infinite ease-in-out both;
}

.preloader .loader span:nth-child(4) {
  bottom: 0;
  right: 0;
}

/*=======================================
   preloader end
  =======================================*/

.stick {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #173d46;
  z-index: 99;
  transform: translateZ(0);
}

/* line animation css - start */
.creasoft-wrap {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

@-webkit-keyframes scroll1 {
  0% {
    top: 0px;
    opacity: 1;
  }

  50% {
    top: 50%;
  }

  100% {
    top: 100%;
    opacity: 0.5;
  }
}

@keyframes scroll1 {
  0% {
    top: 0px;
    opacity: 1;
  }

  50% {
    top: 50%;
  }

  100% {
    top: 100%;
    opacity: 0.5;
  }
}

@-webkit-keyframes scroll2 {
  0% {
    opacity: 1;
    bottom: 0px;
  }

  50% {
    bottom: 50%;
  }

  100% {
    bottom: 100%;
    opacity: 0.5;
  }
}

@keyframes scroll2 {
  0% {
    opacity: 1;
    bottom: 0px;
  }

  50% {
    bottom: 50%;
  }

  100% {
    bottom: 100%;
    opacity: 0.5;
  }
}

.breadcrumbs {
  position: relative;
  height: 480px;
  background-image: url(../img/breadcrumb-bg.jpg);
  background-size: cover;
  background-position: center center;
  z-index: 1;
}

@media (max-width: 767px) {
  .breadcrumbs {
    height: 320px;
  }
}

.breadcrumb-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 480px;
}

@media (max-width: 767px) {
  .breadcrumb-wrapper {
    height: 320px;
  }
}

.breadcrumb-cnt {
  position: relative;
}

.breadcrumb-cnt h1 {
  font-size: 60px;
  font-weight: 700;
  color: #75dab4;
  text-transform: capitalize;
  margin-bottom: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-cnt h1 {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .breadcrumb-cnt h1 {
    font-size: 30px;
  }
}

.breadcrumb-cnt span {
  font-size: 14px;
  color: #fff;
  text-transform: capitalize;
}

.breadcrumb-cnt span a {
  display: inline-block;
  color: #75dab4;
}

.breadcrumb-cnt span i {
  margin: 0 5px;
}

/*Section title*/
.title-wrap {
  max-width: 630px;
}

.sec-title {
  text-align: left;
  margin-bottom: 100px;
}

.sec-title_alt {
  text-align: center;
  margin-bottom: 100px;
}

.sec-title.layout2 {
  text-align: left;
}

.sec-title.white h2,
.sec-title.white p {
  color: #fdfdfd;
}

.sec-title span,
.sec-title h3 {
  font-weight: 400;
  font-size: 45px;
  line-height: 54px;
  display: inline-block;
  color: #3fb7e2;
  text-transform: none;
  padding-bottom: 4px;
  margin-bottom: 5px;
  border-bottom: 2px solid #3fb7e2;
}

.sec-title_alt span,
.sec-title_alt h3 {
  font-size: 25px;
  line-height: 30px;
  border-bottom: none;
}

.sec-title h2 {
  font-weight: 400;
  font-size: 45px;
  line-height: 54px;
  text-transform: none;
  color: #0e2933;
  margin-bottom: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sec-title h2 {
    font-size: 35px;
  }
}

@media (max-width: 767px) {
  .sec-title h2 {
    font-size: 30px;
  }
}

@media (max-width: 449px) {

  .sec-title.sec-title_alt.offer-title {
    margin-bottom: 56px;
  }

  .sec-title.sec-title_alt.offer-title h2 {
    font-size: 29px;
  }

  .sec-title.sec-title_alt.offer-title span {
    font-size: 19px;
  }
}

/*Common button*/
.cmn-btn {
  position: relative;
  display: inline-block;
}

.cmn-btn a {
  position: relative;
  display: inline-block;
  background-color: #efefef;
  border-radius: 8px;
  min-width: 170px;
  font-weight: 500;
  font-size: 19px;
  text-align: center;
  color: #173d46;
  padding: 16px 32px;
  border: 0px solid transparent;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: all 0.8s ease-out 0s;
  transition: all 0.8s ease-out 0s;
  vertical-align: top;
  cursor: pointer;
}

.cmn-btn a:after {
  position: absolute;
  content: "";
  display: block;
  left: -10%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  background-color: #3fb7e2;
  -webkit-transform: skewX(45deg) scale(0, 1);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  -webkit-transition: all 0.8s ease-out 0s;
  transition: all 0.8s ease-out 0s;
}

.cmn-btn a:hover {
  color: #07151a;
}

.cmn-btn a:hover:after {
  -webkit-transform: skewX(45deg) scale(1, 1);
  transform: skewX(45deg) scale(1, 1);
}

.slidenav ul li a {
  color: #565656;
}

.slidenav ul li {
  display: flex;
  justify-content: center;
}

/*Responsive*/

.page-section img.w-100 {
  height: 45vh;
}

/*SubPages*/
.page-section {
  margin-bottom: 140px;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 120%;
}

span,
p {
  line-height: 150%;
}

h1,
h2,
h3,
h4,
h5,
span,
p {
  font-family: "Saira";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.01em;
}

h3,
h5 {
  text-align: center;
}

h3 {
  font-size: 45px;
}

h5 {
  color: #3fb7e2;
  font-size: 25px;
  text-transform: uppercase;
}

span {
  color: #3fb7e2;
}

/*OurServices*/

.services {
  background-color: #2c7789;
  height: fit-content;
}

.services h4,
.services p {
  color: #efefef;
}

/*Title*/

.title {
  position: absolute;
  /* left: 16%; */
  top: 45%;
  font-family: "Saira";
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #efefef;
}

@media (max-width: 998px) {
  .title {
    top: 40%;
  }
}

@media (max-width: 439px) {
  .title {
    top: 35%;
  }
}

@media (max-width: 399px) {
  .title {
    top: 30%;
    margin-right: 20px;
  }
}

@media (max-width: 315px) {
  .title {
    top: 35%;
  }
}

@media (max-width: 288px) {
  .title {
    top: 30%;
  }
}

@media (max-width: 258px) {
  .title {
    top: 25%;
  }
}

/*Services*/
.services {
  color: #efefef;
  border-radius: 1rem;
}

.services a {
  font-size: 16px;
  text-decoration: none;
  color: #efefef;
  transition: 0.3s;
}

.services a:hover {
  color: #3fb7e2;
}

.services h3 {
  text-align: left;
}

.line {
  margin: 20px 0;
  border: 1px solid #3fb7e2;
}

/*================================
    Responsive mobile menu css
=================================*/
@media only screen and (max-width: 1199px) {
  /* header .main-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 260px;
    padding: 10px 0 !important;
    z-index: 99999;
    height: 100vh;
    overflow: auto;
    display: block;
    background: #fdfdfd;
    -webkit-transform: translateX(-260px);
    transform: translateX(-260px);
    -webkit-transition: -webkit-transform 0.3s ease-in;
    transition: -webkit-transform 0.3s ease-in;
    transition: transform 0.3s ease-in;
    transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
  } */

  /* header .main-nav .mobile-menu-logo {
    position: relative;
    text-align: left;
    padding: 20px 16px;
    margin-bottom: 20px;
    display: block;
    border-bottom: 1px solid #3fb7e2;
  } */

  /* header .main-nav ul {
    float: none;
    text-align: left;
    padding: 0;
  }

  header .main-nav ul li {
    position: relative;
    display: block;
    border-bottom: 1px solid #eee;
    padding: 10px 16px !important;
  } */

  header .main-nav ul li:last-child {
    border: none;
  }

  /* header .main-nav ul li a {
    padding: 10px 0;
    display: block;
    font-weight: 400;
    color: #565656;
    text-transform: capitalize;
  } */

  /* header .main-nav ul li ul.sub-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: none;
    transform: none;
    -webkit-transition: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  } */

  /* header .main-nav ul li ul.sub-menu li {
    padding: 0 !important;
  }

  header .main-nav ul li ul.sub-menu li a {
    padding: 16px 26px;
    color: #565656;
  } */
  /* 
  header .main-nav.slidenav {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  } */
  /* 
  header .main-nav ul li .bi {
    display: block;
    top: 18px;
    right: 20px;
    font-size: 16px;
    color: #565656;
  } */

  /* @media (max-width: 1199px) {
    header .main-nav ul li .bi {
      top: 8px;
      right: 20px;
    }
  } */
  /* 
  .mobile-menu {
    position: relative;
    top: 2px;
    padding: 0 5px;
    border-radius: 50%;
    display: inline-block;
    text-align: right;
    margin-left: 30px;
  } */

  .cross-btn {
    display: inline-block !important;
    position: relative;
    width: 30px !important;
    height: 22px !important;
    cursor: pointer;
    border: 3px solid transparent !important;
  }

  .cross-btn span {
    width: 100%;
    height: 2px;
    background: #fdfdfd !important;
    display: block;
    position: absolute;
    right: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .cross-btn .cross-top {
    top: 0;
  }

  .cross-btn .cross-middle {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
  }

  .cross-btn .cross-bottom {
    bottom: 0;
    width: 100%;
  }
}

/* @media only screen and (min-width: 1199px) {
  .main-menu ul li:hover ul.sub-menu {
    display: block !important;
    -webkit-transition: all linear 0.3s;
    transition: all linear 0.3s;
  }
} */

/* .cursor {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 30px;
  height: 30px;
  z-index: 999999;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transition: -webkit-transform 0.35s;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: scale(0.35) translate(-50%, -50%);
  transform: scale(0.35) translate(-50%, -50%);
  mix-blend-mode: exclusion;
  background-color: #fff;
} */

/*===========================
     02.Header css
===========================*/
header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.header-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* padding: 0px 150px; */
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  max-height: 84px;
}

/* @media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area {
    padding: 20px 30px;
  }
} */

/* @media (max-width: 767px) {
  .header-area {
    padding: 20px 10px;
  }
} */

.logo a {
  display: inline-block;
}

.logo a img {
  max-width: 170px;
}

@media only screen and (max-width: 1199px) {
  .logo a img {
    padding: 25px 0px;
  }
}

.main-nav {
  text-align: center;
}

.main-nav ul {
  list-style: none;
}

.main-nav ul li {
  display: inline-block;
  position: relative;
  padding: 0 20px;
  color: #fdfdfd;
}

.header-area.alternative .main-nav ul li {
  color: #173d46;
}

.main-nav ul li:first-child {
  padding-left: 0;
}

.main-nav ul li a {
  position: relative;
  font-family: "Saira", sans-serif;
  font-size: 16px;
  padding: 30px 0;
  font-weight: 400;
  line-height: 24px;
  color: #fdfdfd;
  display: inline-block;
  text-transform: none;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  cursor: pointer;
}

.main-nav ul li a.active {
  color: #3fb7e2;
}

.main-nav ul li:hover {
  color: #3fb7e2;
}

.main-nav ul li a.active + i {
  color: #3fb7e2;
}

.main-nav ul li:hover a {
  color: #3fb7e2;
}

.main-nav ul li i {
  position: absolute;
  top: 36px;
  right: 7%;
  font-size: 8.7px;
  text-align: center;
  z-index: 999;
  cursor: pointer;
  transition: 0.3s;
}

.main-nav ul li ul.sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  min-width: 200px;
  background-color: #fdfdfd;
  text-align: left;
  z-index: 9;
  -webkit-box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.main-nav ul li ul.sub-menu > li {
  padding: 0;
  display: block;
  border-bottom: 1px solid #eee;
}

.main-nav ul li ul.sub-menu > li:last-child {
  border: none;
}

.main-nav ul li ul.sub-menu > li a {
  position: relative;
  display: block;
  padding: 12px 20px;
  color: #000;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 1;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.main-nav ul li ul.sub-menu > li a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(96.98deg, #3fb7e2 0%, #ff5f45 100%);
  width: 100%;
  height: 1px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transition: 0.4s ease-in;
  transition: 0.4s ease-in;
  z-index: -1;
}

.main-nav ul li ul.sub-menu > li a.active {
  font-weight: 500;
  color: #3fb7e2;
}

.main-nav ul li ul.sub-menu > li a:hover {
  color: #3fb7e2;
}

.main-nav ul li ul.sub-menu > li a:hover:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* .main-nav ul li:hover ul.sub-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
} */

.nav-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1199px) {
  .nav-right {
    display: flex;
  }
}

/*===========================
     03.Hero css
===========================*/
.hero-area {
  position: relative;
  overflow: hidden;
}

@media (max-width: 767px) {
  .hero-area {
    height: 75vh;
  }
}

.hero-area {
  position: relative;
}

@-webkit-keyframes kenburns {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  95% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5) translate3d(-100px, -80px, 0px);
    transform: scale3d(1.5, 1.5, 1.5) translate3d(-100px, -80px, 0px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }

  100% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5) translate3d(-90px, -70px, 0px);
    transform: scale3d(1.5, 1.5, 1.5) translate3d(-90px, -70px, 0px);
    opacity: 0;
  }
}

@keyframes kenburns {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  95% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5) translate3d(-100px, -80px, 0px);
    transform: scale3d(1.5, 1.5, 1.5) translate3d(-100px, -80px, 0px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }

  100% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5) translate3d(-90px, -70px, 0px);
    transform: scale3d(1.5, 1.5, 1.5) translate3d(-90px, -70px, 0px);
    opacity: 0;
  }
}

.hero-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100vh;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-wrapper {
    padding-top: 100px;
  }
}

@media (max-width: 767px) {
  .hero-wrapper {
    height: 80vh;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1699px) {
  .hero-content {
    /*padding-left: 100px;*/
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-content {
    padding-left: 50px;
  }
}

.hero-content.layout2 h1 {
  text-transform: capitalize;
}

.hero-content.layout2 h1 span {
  text-transform: uppercase;
}

.hero-content h1 {
  font-weight: 400;
  font-size: 95px;
  text-transform: none;
  color: #fdfdfd;
  margin-bottom: 12px;
  line-height: 114px;
}

@media only screen and (min-width: 1400px) and (max-width: 1699px) {
  .hero-content h1 {
    /*font-size: 60px;*/
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-content h1 {
    font-size: 48px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content h1 {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .hero-content h1 {
    font-size: 30px;
    line-height: 42px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content h1 {
    font-size: 40px;
  }
}

.hero-content p {
  font-weight: 400;
  font-size: 45px;
  color: #fdfdfd;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content p {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .hero-content p {
    font-size: 14px;
  }
}

/* The typing effect */
@-webkit-keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.buttons {
  margin-top: 40px;
}

.buttons .cmn-btn {
  margin-right: 20px;
}

@media (max-width: 767px) {
  .buttons .cmn-btn {
    margin-right: 10px;
  }
}

.buttons .cmn-btn:last-child {
  margin: 0;
}

@-webkit-keyframes rotateme {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    opacity: 1;
  }
}

@keyframes rotateme {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    opacity: 1;
  }
}

/*===========================
     04.Services css
===========================*/

/*===========================
     05.About css
===========================*/

@-webkit-keyframes ripple {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(117, 218, 180, 0.3),
      0 0 0 0 rgba(117, 218, 180, 0.5);
    box-shadow: 0 0 0 0 rgba(117, 218, 180, 0.3),
      0 0 0 0 rgba(117, 218, 180, 0.5);
  }

  80% {
    -webkit-box-shadow: 0 0 0 20px #fff0, 0 0 0 40px #fff0;
    box-shadow: 0 0 0 20px #fff0, 0 0 0 40px #fff0;
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 #fff0, 0 0 0 0 #fff0;
    box-shadow: 0 0 0 0 #fff0, 0 0 0 0 #fff0;
  }
}

@keyframes ripple {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(117, 218, 180, 0.3),
      0 0 0 0 rgba(117, 218, 180, 0.5);
    box-shadow: 0 0 0 0 rgba(117, 218, 180, 0.3),
      0 0 0 0 rgba(117, 218, 180, 0.5);
  }

  80% {
    -webkit-box-shadow: 0 0 0 20px #fff0, 0 0 0 40px #fff0;
    box-shadow: 0 0 0 20px #fff0, 0 0 0 40px #fff0;
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 #fff0, 0 0 0 0 #fff0;
    box-shadow: 0 0 0 0 #fff0, 0 0 0 0 #fff0;
  }
}

/*===========================
     Stages Of Development css
===========================*/

.point {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  border-bottom: 2px solid #efefef;
}

.point h1 {
  font-size: 95px;
  line-height: 114px;
  color: #3fb7e2;
}

.point p {
  font-size: 34px;
  color: #efefef;
}

/*===========================
     06.Feature css
===========================*/

/* Top left icon styles */

.icon {
  z-index: 10;
  position: relative;
}

/* Read More styles */

/*===========================
     07.Project css
===========================*/

/*===========================
     12.Blog css
===========================*/
.single-blog .blog-thumb {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
}

.single-blog .blog-thumb a img {
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.single-blog .blog-thumb .tag {
  position: absolute;
  top: 20px;
  left: 20px;
}

.single-blog .blog-thumb .tag a {
  font-weight: 600;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  color: #0f1012;
  background-color: #75dab4;
  padding: 5px 15px;
  border-radius: 30px;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.single-blog .blog-thumb .tag a:hover {
  background-color: #fff;
  color: #75dab4;
}

.single-blog .blog-inner {
  padding: 20px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 10px 10px;
}

.single-blog .blog-inner h4 {
  margin-top: 15px;
  margin-bottom: 5px;
}

.single-blog .blog-inner h4 a {
  font-weight: 600;
  font-size: 22px;
  display: inline-block;
  text-transform: capitalize;
  color: #17161a;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.single-blog .blog-inner h4 a:hover {
  color: #75dab4;
}

.single-blog .blog-inner .author-date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.single-blog .blog-inner .author-date a {
  font-weight: 500;
  font-size: 17px;
  text-transform: capitalize;
  color: #5f6062;
  display: inline-block;
}

.single-blog:hover .blog-thumb a img {
  -webkit-transform: scale(1.1) skewY(5deg);
  transform: scale(1.1) skewY(5deg);
}

/*===========================
     13.Subscribe css
===========================*/
.subscribe-newsletter {
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .subscribe-newsletter {
    margin-top: 80px;
  }
}

.news-letter-content {
  position: relative;
  padding: 60px 80px;
  border-radius: 10px;
  background-color: #1a191d;
  background-image: url(../img/subscribe-bg.png);
  background-size: cover;
  background-position: center center;
}

@media (max-width: 767px) {
  .news-letter-content {
    padding: 30px 20px;
  }
}

.subscribe-cnt span {
  font-weight: 600;
  font-size: 20px;
  display: block;
  text-transform: capitalize;
  color: #75dab4;
}

.subscribe-cnt h3 {
  font-weight: 700;
  font-size: 45px;
  text-transform: uppercase;
  color: #e4e4e4;
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  .subscribe-cnt h3 {
    font-size: 30px;
  }
}

.subscribe-cnt strong {
  -webkit-text-stroke: 1px #5f5f5f;
  -webkit-text-fill-color: transparent;
  font-size: 45px;
  text-transform: uppercase;
  font-weight: 700;
}

@media (max-width: 767px) {
  .subscribe-cnt strong {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .subscribe-form {
    margin-top: 15px;
  }
}

.subscribe-form form {
  position: relative;
}

.subscribe-form form input {
  height: 60px;
  width: 100%;
  background-color: #0f0f11;
  border-radius: 10px;
  border: none;
  padding: 0 20px;
  color: #e4e4e4;
}

.subscribe-form form input[type="email"] {
  padding-right: 160px;
}

@media (max-width: 767px) {
  .subscribe-form form input[type="email"] {
    padding: 0 10px;
  }
}

.subscribe-form form input[type="submit"] {
  max-width: 140px;
  background-color: #75dab4;
  border-radius: 10px;
  position: absolute;
  right: 5px;
  height: 50px;
  top: 5px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

@media (max-width: 767px) {
  .subscribe-form form input[type="submit"] {
    position: static;
    margin-top: 15px;
  }
}

.subscribe-form form input[type="submit"]:hover {
  color: #75dab4;
  background-color: #fff;
}

/*===========================
     14.Footer css
===========================*/
footer {
  position: relative;
  margin-top: -100px;
  background-color: #173d46;
  height: 912px;
  z-index: 12;
}

footer.error {
  padding-top: 0;
  margin-top: 0;
}

.footer-top {
  padding-top: 100px;
}

@media (max-width: 767px) {
  .footer-top {
    padding-top: 80px;
  }
}

@media (max-width: 767px) {
  .footer-widget {
    margin-bottom: 50px;
  }
}

.footer-widget {
  max-width: 250px;
}

.footer-widget .footer-logo {
  margin-bottom: 30px;
}

.footer-widget .footer-logo a img {
  width: 85%;
}

.footer-widget h4 {
  font-weight: 400;
  font-size: 25px;
  color: #fdfdfd;
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget h4 {
    font-size: 24px;
  }
}

.footer-widget p {
  font-weight: 500;
  font-size: 14px;
  color: #fdfdfd;
  line-height: 21px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget p {
    font-size: 14px;
  }
}

.footer-menu {
  list-style: none;
}

.footer-menu li {
  display: block;
  padding-bottom: 15px;
  color: #fdfdfd;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 441px) {
  .footer-menu li {
    padding-bottom: 20px;
  }
}

.footer-menu li:last-child {
  padding-bottom: 0;
}

.footer-menu li a {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fdfdfd;
  display: inline-block;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu li a {
    font-size: 15px;
  }
}

.footer-menu li a:before {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -20px;
  height: 10px;
  width: 10px;
  background-color: #3fb7e2;
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.footer-menu li a:hover {
  color: #3fb7e2;
  padding-left: 10px;
}

.footer-menu li a:hover:before {
  left: -5px;
  visibility: visible;
  opacity: 1;
}

.number,
.address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.number p,
.address p {
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.03em;
  color: #e4e4e4;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .number p,
  .address p {
    font-size: 14px;
  }
}

.phone a,
.email a {
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.03em;
  color: #e4e4e4;
  display: block;
  padding-bottom: 5px;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .phone a,
  .email a {
    font-size: 13px;
  }
}

.phone a:hover,
.email a:hover {
  color: #75dab4;
}

.footer-bottom {
  padding: 30px 0;
  height: 64px;
  width: 100%;
  background-color: #235f6e;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (max-width: 767px) {
  .footer-bottom {
    margin-top: 30px !important;
  }
}

@media (max-width: 767px) {
  .footer-bottom .copy-txt {
    text-align: center;
  }
}

.footer-bottom .copy-txt span {
  font-size: 14px;
  color: #efefef;
  font-weight: 500;
  display: flex;
  gap: 6px;
}

@media (max-width: 500px) {
  .footer-bottom {
    height: auto;
    flex-direction: column;
    padding: 16px 0;
  }

  .footer-bottom .copy-txt {
    flex-direction: column;
    gap: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-bottom .copy-txt span {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .footer-bottom .copy-txt span {
    font-size: 13px;
  }
}

.footer-bottom .copy-txt span a {
  font-weight: 700;
  color: #e4e4e4;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.footer-bottom .copy-txt span a:hover {
  color: #75dab4;
}

.offer-download_btn a {
  font-size: 19px;
  font-weight: 500;
  color: #3fb7e2;
  border-bottom: 1px solid #3fb7e2;
  transition: 0.2s;
}

.offer-download_btn a:hover {
  color: #efefef;
  border-bottom: 1px solid #efefef;
}

/*===========================
     15.Home two css
===========================*/
.creasoft-wrap.layout2 {
  background-image: url(../img/black-concrete-textured.png);
  background-repeat: repeat;
  background-color: #000000;
}

.single-blog.layout2 .blog-inner {
  background: transparent;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.single-blog.layout2 .blog-inner .author-date a {
  color: #5f6062;
}

.single-blog.layout2 .blog-inner h4 a {
  color: #e4e4e4;
}

.single-blog.layout2:hover .blog-inner {
  background: linear-gradient(
    357.81deg,
    rgba(39, 39, 46, 0.6445) 1.75%,
    rgba(74, 75, 86, 0.0072) 98.16%
  );
  border-radius: 0px 0px 10px 10px;
}

/*===========================
     16.Contact page css
===========================*/

.info h3 {
  font-size: 30px;
  font-weight: 600;
  color: #17161a;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.info p {
  font-size: 16px;
  font-weight: 500;
  color: #272727;
}

.info a {
  font-size: 16px;
  font-weight: 500;
  color: #272727;
  display: block;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.info a:hover {
  color: #75dab4;
}

/*===========================
     17.Error page css
===========================*/

/*===========================
     18.FAQ page css
===========================*/

/*===========================
     19.Service page css
===========================*/

.sidebar-widget {
  border: 1px solid #f0f0f0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 35px 20px;
  margin-bottom: 40px;
}

.sidebar-widget:last-child {
  margin-bottom: 0;
}

.sidebar-widget h4 {
  font-weight: 700;
  font-size: 22px;
  display: inline-block;
  text-transform: uppercase;
  color: #17161a;
  margin-bottom: 20px;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

.category {
  list-style: none;
}

.category li {
  margin-bottom: 15px;
}

.category li:last-child {
  margin: 0;
}

.category li a {
  font-weight: 500;
  font-size: 16px;
  color: #272727;
  position: relative;
  display: block;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.category li a i {
  position: absolute;
  right: 0;
}

.category li a:hover {
  color: #75dab4;
}

/*===========================
     20.Project page css
===========================*/

.label {
  position: absolute;
  top: 0;
}

.label span {
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  background-color: #17161a;
  padding: 5px 20px;
  display: inline-block;
}

.check {
  list-style: none;
}

@media (max-width: 767px) {
  .check {
    margin-bottom: 20px;
  }
}

.check li {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 18px;
  display: block;
  color: #17161a;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .check li {
    font-size: 15px;
  }
}

.check li:last-child {
  margin: 0;
}

.check li i {
  margin-right: 10px;
}

.contact-info h3 {
  font-weight: 600;
  font-size: 35px;
  color: #17161a;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .contact-info h3 {
    font-size: 30px;
  }
}

.contact-info p {
  font-size: 15px;
}

/*===========================
     21.About page css
===========================*/

.years {
  background-color: #17161a;
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  padding: 20px;
}

.years h5 {
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  color: #75dab4;
  margin-bottom: 5px;
}

.years span {
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  color: #ffffff;
}

.history {
  position: relative;
  min-width: 50%;
}

.history:first-child {
  padding-right: 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .history:first-child {
    padding-right: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .history:first-child {
    padding: 0;
  }
}

.history:first-child .circle {
  position: absolute;
  right: -7px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  bottom: 60px;
}

.history:last-child {
  padding-left: 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .history:last-child {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .history:last-child {
    padding: 0;
  }
}

.history:last-child .circle {
  position: absolute;
  left: -7px;
  top: 60px;
}

.circle {
  position: relative;
  height: 14px;
  width: 14px;
  background: #75dab4;
  border-radius: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .circle {
    display: none;
  }
}

.circle:before {
  position: absolute;
  content: "";
  left: -3px;
  top: -3px;
  height: 20px;
  width: 20px;
  border: 1px solid #75dab4;
  border-radius: 50%;
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
  transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
}

.circle:after {
  position: absolute;
  content: "";
  left: -6px;
  top: -6px;
  height: 26px;
  width: 26px;
  border: 1px solid #75dab4;
  border-radius: 50%;
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
  transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
}

.circle .inner {
  width: 70px;
  border: 1.5px dashed #75dab4;
  position: relative;
  top: 5.5px;
  left: 15px;
  border-radius: 10px;
}

/*===========================
     22.Blog page css
===========================*/

.widget-search {
  position: relative;
}

.widget-search form input {
  background-color: #f0f0f0;
  border-radius: 3px;
  border: none;
  height: 50px;
  width: 100%;
  padding: 0 10px;
}

.widget-search form input[type="text"] {
  padding-right: 75px;
}

.widget-search form button {
  position: absolute;
  border: none;
  font-size: 20px;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #17161a;
  background: #75dab4;
  border-radius: 0px 3px 3px 0px;
  height: 50px;
  width: 70px;
}

.load-more {
  text-align: center;
  margin-top: 50px;
}

.paginations li {
  display: inline-block;
  margin: 0 2.5px;
}

.paginations li.active a {
  color: #fff;
  border: 1px solid #75dab4;
  background-color: #75dab4;
}

.paginations li a {
  border: 1px solid rgba(23, 22, 26, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  color: #d3d3d3;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.paginations li a:hover {
  color: #fff;
  background-color: #75dab4;
}

@media (max-width: 767px) {
  .blog-standard {
    margin-top: 80px;
  }
}

@media (max-width: 767px) {
  .blog-standard-details {
    margin-top: 40px;
  }
}

.single-blog.layout4 {
  margin-top: 50px;
}

.single-blog.layout4 .blog-inner h4 {
  margin-bottom: 10px;
}

.single-blog.layout4 .blog-inner h4 a {
  font-size: 26px;
}

@media (max-width: 767px) {
  .single-blog.layout4 .blog-inner h4 a {
    font-size: 20px;
  }
}

.single-blog.layout4 .blog-thumb:hover a img {
  border-radius: 10px;
  -webkit-transform: scale(1.1) skewY(0);
  transform: scale(1.1) skewY(0);
}

@media (max-width: 767px) {
  .blog-details-area {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .blog-details-content {
    margin-bottom: 50px;
  }
}

.blog-details-content h3 {
  text-align: left;
  font-size: 25px;
  text-transform: capitalize;
  color: #173d46;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .blog-details-content h3 {
    font-size: 22px;
  }
}

.blog-details-content h4 {
  font-weight: 600;
  font-size: 27px;
  text-transform: capitalize;
  color: #17161a;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .blog-details-content h4 {
    font-size: 22px;
  }
}

.blog-details-content p {
  font-size: 15px;
  color: #272727;
  text-align: justify;
}

.blog-dropdown-shadow {
  box-shadow: 0px 2px 5px 0px #0000000d, 0px 8px 8px 0px #0000000a,
    0px 19px 11px 0px #00000008, 0px 33px 13px 0px #00000003,
    0px 52px 15px 0px #00000000;
}
.author-date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 15px;
}

.author-date.layout2 {
  margin-bottom: 30px;
}

.author-date a {
  font-weight: 500;
  font-size: 17px;
  text-transform: capitalize;
  color: #5f6062;
  display: inline-block;
}

.special {
  margin-bottom: 10px;
}

.special p span {
  font-size: 75px;
  color: #272727;
  float: left;
  line-height: 55px;
}

.tag-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 50px 0;
  border-bottom: 1px solid #eeeeee;
}

@media (max-width: 767px) {
  .tag-share {
    display: block;
  }
}

.share-blog {
  min-width: 50%;
  border: 1px solid #f0f0f0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 30px;
  text-align: center;
}

.share-blog span {
  font-weight: 600;
  font-size: 22px;
  display: inline-block;
  color: #17161a;
  margin-right: 5px;
}

.social-share-blog {
  display: inline-block;
  list-style: none;
}

.social-share-blog li {
  display: inline-block;
  margin: 0 10px;
}

.social-share-blog li a {
  display: inline-block;
  color: #909090;
  font-size: 16px;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.social-share-blog li a:hover {
  color: #75dab4;
}

.reply {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .reply {
    display: none;
  }
}

.reply a {
  border: 1px solid #f0f0f0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: #111111;
  padding: 5px 10px;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.reply a i {
  margin-right: 5px;
}

.reply a:hover {
  color: #75dab4;
  border: 1px solid #75dab4;
}

/*Responsive styles*/

/*1. Hero area*/

@media (max-width: 1599px) {
  .hero-content h1 {
    font-size: 80px;
    margin-bottom: 15px;
  }

  .hero-content p {
    font-size: 40px;
  }
}

@media (max-width: 1599px) {
  .hero-content h2 {
    font-size: 40px;
  }
}

@media (max-width: 929px) {

  .hero-content h1 {
    font-size: 65px;
    margin-bottom: 0px;
  }

  .hero-content p,
  .hero-content h2 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .hero-wrapper {
    height: 80vh;
  }

  .hero-content h1 {
    font-size: 60px;
    margin-bottom: 25px;
  }
}

@media (max-width: 699px) {

  .hero-content h1 {
    font-size: 55px;
    margin-bottom: 25px;
  }

  .hero-content p,
  .hero-content h2 {
    font-size: 26px;
  }
}

@media (max-width: 599px) {

  .hero-content h1 {
    font-size: 46px;
    margin-bottom: 12px;
  }

  .hero-content p {
    font-size: 24px;
  }
}

@media (max-width: 569px) {

  .hero-content h1 {
    font-size: 46px;
    margin-bottom: 12px;
  }

  .hero-content p,
  .hero-content h2 {
    font-size: 24px;
  }
}

@media (max-width: 449px) {
  .hero-content h1 {
    font-size: 39px;
  }

  .hero-content p {
    font-size: 19px;
  }
}

@media (max-width: 339px) {
  .hero-content h1 {
    font-size: 35px;
  }

  .hero-content p {
    font-size: 17px;
  }
}

@media (max-width: 305px) {
  .hero-content {
    max-width: 270px;
  }
}

/*Height hero area icon styles*/

@media (max-height: 420px) and (max-width: 915px) and (orientation: landscape) {

  .hero-area {
    padding-top: 20px;
    height: auto;
  }
}

@media (max-height: 389px) and (max-width: 800px) and (orientation: landscape) {

  .hero-content h1 {
    font-size: 50px;
    line-height: 70px;
  }

  .hero-content p {
    font-size: 22px;
    margin-bottom: -40px;
  }
}

@media (max-height: 380px) and (max-width: 700px) and (orientation: landscape) {
  .hero-content h1 {
    font-size: 40px;
    line-height: 10px;
  }

  .hero-content p {
    font-size: 19px;
    margin-bottom: -20px;
  }

  .hero-area {
    padding-top: 70px;
  }
}

@media (max-height: 415px) and (orientation: landscape) {
  .header-area {
    height: 70px;
  }
}

/*3. Best choice area*/

@media (max-width: 1199px) {
  .sec-title span,
  .sec-title h3 {
    font-size: 35px;
    padding-bottom: 0px;
  }
}

@media (max-width: 991px) {
  .sec-title h2 {
    font-size: 38px;
  }

  .sec-title span,
  .sec-title h3 {
    font-size: 38px;
  }
}

@media (max-width: 767px) {
  .sec-title h2 {
    text-align: center;
  }
}

/*4. Swiper technologies*/

@media (max-width: 1199px) {
  .swiper {
    max-width: 600px;
    padding-left: 0;
    margin-left: 120px;
  }
}

@media (max-width: 991px) {
  .swiper {
    max-width: 100%;
    margin-left: 0px;
    padding-top: 20px;
  }
}

@media (max-width: 449px) {
  .sec-title h2 {
    font-size: 29px;
    line-height: 35px;
  }

  .swiper {
    max-width: 100%;
  }
}

/*5. About*/

@media (max-width: 1399px) {
}

/*6. Stages of development*/

@media (max-width: 1299px) {
  .point p {
    max-width: 380px;
    text-align: right;
  }

  .point {
    align-items: center;
  }

  @media (max-width: 1199px) {

    .point p {
      font-size: 28px;
    }

    .point h1 {
      font-size: 88px;
    }
  }
}

@media (max-width: 911px) {
  .point p {
    font-size: 26px;
    max-width: 320px;
  }

  .point h1 {
    font-size: 85px;
  }
}

@media (max-width: 849px) {
  .point p {
    font-size: 24px;
    max-width: 300px;
  }

  .point h1 {
    font-size: 82px;
  }
}

@media (max-width: 569px) {
  .point p {
    font-size: 22px;
    max-width: 280px;
  }
}

@media (max-width: 499px) {
  .point p {
    max-width: 200px;
  }
}

@media (max-width: 379px) {
  .point p {
    max-width: 180px;
    line-height: 26px;
  }
}

@media (max-width: 339px) {
  .point p {
    max-width: 150px;
  }
}

@media (max-width: 299px) {
  .point h1 {
    font-size: 70px;
  }

  .point p {
    font-size: 18px;
    max-width: 100px;
  }
}

/*9. Stages of development*/

@media (max-width: 1199px) {
  footer {
    padding: 0 50px;
  }
}

@media (max-width: 1149px) {
  .footer-widget {
    max-width: 250px;
  }
}

@media (max-width: 887px) {
  footer {
    height: auto;
  }

  .footer-top {
    padding-bottom: 140px;
    padding-top: 72px;
  }

  .footer-top .row {
    flex-direction: row;
  }

  .footer-widget {
    width: 100%;
  }
}

@media (max-width: 441px) {

  footer {
    padding: 0 16px;
  }

  .footer-top {
    padding-bottom: 90px;
  }
}

@media (max-width: 359px) {

  .offer-download_btn {
    margin-top: 30px;
  }
}

/*  Sub pages */

@media (max-width: 991px) {

  .services {
    border-radius: 0;
    margin-bottom: 100px;
  }
}

@media (max-width: 825px) {
  .services {
    margin-top: 72px;
  }
}

/*Web-Mobile*/

/*Machine learning*/

/*UI/UX*/

/*Case Studies*/

.page-section img {
  object-fit: cover;
}

@media (max-width: 1399px) {
  .page-section .container {
    padding: 0 50px;
  }
}

@media (max-width: 699px) {
  .page-section .container {
    padding: 0 16px;
  }
}

@media (max-width: 399px) {
  .page-section .title {
    font-size: 34px;
  }
}

@media (max-width: 399px) {
  .page-section .title {
    font-size: 30px;
    top: 40%;
  }
}

@media (max-height: 500px) and (orientation: landscape) {
  .page-section img.w-100 {
    height: 50vh;
  }

  .page-section .title {
    font-size: 40px;
  }
}

@media (max-height: 399px) and (orientation: landscape) {
  .page-section img.w-100 {
    height: 60vh;
  }

  .page-section .title {
    font-size: 34px;
  }
}

/*1. Oddsee*/
.app-data {
  height: 120px;
  display: flex;
  align-items: center;
  font-size: 25px;
  color: #235f6e;
}

@media (max-width: 799px) {
  .app-data {
    height: 120px;
    font-size: 22px;
  }
}

.app-data h3 {
  margin-right: 100px;
}

@media (max-width: 599px) {
  .app-data {
    justify-content: start;
    font-size: 20px;
    line-height: 24px;
  }

  @media (max-width: 500px) {
    .app-data {
      font-size: 16px;
    }
  }

  .app-data h3 {
    margin-right: 0;
  }

  .app-data h3:nth-child(1) {
    margin-right: 32px;
  }
}

.about-app {
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-app.second {
  flex-direction: row-reverse;
}
@media (max-width: 991px) {
  .about-app.second {
    flex-direction: column;
  }
}
.about-app .text {
  max-width: 555px;
}

@media (max-width: 1119px) {
  .about-app .text {
    max-width: 400px;
  }
}

@media (max-width: 991px) {
  .about-app {
    flex-direction: column;
    margin-top: 56px;
  }

  .about-app .text {
    max-width: 100%;
    margin-bottom: 56px;
  }
}

@media (max-width: 599px) {
  .about-app .text {
    max-width: 100%;
    margin-bottom: 56px;
  }
}

.text h1 {
  font-size: 34px;
  line-height: 41px;
  color: #256c86;
  font-weight: 400;
}

@media (max-width: 799px) {
  .text h1 {
    font-size: 28px;
    line-height: 41px;
  }
}

.about-app .text h1 {
  max-width: 500px;
  font-size: 34px;
  line-height: 41px;
  color: #256c86;
  font-weight: 400;
  margin-bottom: 24px;
}

@media (max-width: 911px) {
  .about-app .text h1 {
    text-align: center;
    max-width: 100%;
  }
}

@media (max-width: 799px) {
  .about-app .text h1 {
    font-size: 28px;
  }
}

@media (max-width: 679px) {
  .about-app .text h1 {
    font-size: 22px;
    line-height: 26px;
  }
}

@media (max-width: 500px) {
  .about-app .text h1 {
    text-align: left;
  }
}

.about-app .text p {
  font-size: 16px;
  line-height: 24px;
  color: #565656;
}

.about-app img {
  width: auto;
  height: auto;
}

.process {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.process img {
  margin-top: 45px;
}

.about-app_alt {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
}

.about-app_alt .text {
  max-width: 555px;
}

@media (max-width: 1289px) {
  .about-app_alt .text {
    max-width: 400px;
  }
}

@media (max-width: 1139px) {
  .oddsee-phones {
    max-width: 450px;
  }
}

@media (max-width: 1029px) {
  .oddsee-phones {
    max-width: 400px;
  }
}

@media (max-width: 991px) {
  .about-app_alt {
    flex-direction: column;
    margin-top: 72px;
  }

  .about-app_alt .text {
    max-width: 100%;
    margin-bottom: 56px;
  }
}

.about-app_alt img {
  width: auto;
  height: auto;
}

.about-app_alt .text h1 {
  max-width: 500px;
  font-size: 34px;
  line-height: 41px;
  color: #256c86;
  font-weight: 400;
  margin-bottom: 24px;
}

@media (max-width: 911px) {
  .about-app_alt .text h1 {
    text-align: center;
    max-width: 100%;
  }
}

@media (max-width: 799px) {
  .about-app_alt .text h1 {
    font-size: 28px;
  }
}

@media (max-width: 679px) {
  .about-app_alt .text h1 {
    font-size: 22px;
    line-height: 26px;
  }
}

@media (max-width: 500px) {
  .about-app_alt .text h1 {
    text-align: left;
  }
}

@media (max-width: 599px) {
  .about-app_alt .text {
    max-width: 100%;
  }
}

.about-app_alt .text p {
  font-size: 16px;
  line-height: 24px;
  color: #565656;
}

/*.wireframes {*/
/*  height: 1000px;*/
/*  background-color: #234549;*/
/*  margin-top: 140px;*/
/*  text-align: center;*/
/*}*/

.wireframes {
  display: flex;
  justify-content: center;
  background-color: #234549;
  margin-top: 144px;
}

@media (max-width: 911px) {
  .wireframes {
    margin-top: 80px;
  }
}

@media (max-width: 599px) {
  .wireframes {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

.wireframes.text h1,
p {
  color: #fdfdfd;
}

.wireframes.text h1 {
  margin-bottom: 24px;
  padding-top: 56px;
}

.about-app .alt {
  width: 560px;
}

.img-line {
  margin-top: 150px;
  display: flex;
  justify-content: center;
}

.img-line img {
  margin-right: 10px;
  margin-left: 10px;
  max-width: 420px;
}

@media (max-width: 1399px) {
  .img-line img {
    max-width: 394px;
  }
}

@media (max-width: 1249px) {
  .img-line img {
    max-width: 360px;
  }
}

@media (max-width: 1199px) {
  .img-line img {
    max-width: 345px;
  }
}

@media (max-width: 1099px) {
  .img-line {
    margin-top: 100px;
  }
}

@media (max-width: 1069px) {
  .img-line img {
    max-width: 300px;
  }
}

@media (max-width: 991px) {
  .img-line img {
    max-width: 250px;
  }
}

@media (max-width: 899px) {
  .img-line {
    flex-direction: column;
    align-items: center;
  }

  .img-line img {
    max-width: none;
    margin-bottom: 15px;
  }
}

@media (max-width: 699px) {
  .img-line img {
    max-width: 400px;
  }

  .img-line img:last-child {
    margin-bottom: 0;
  }

  .img-line {
    margin-top: 56px;
    align-items: center;
    flex-direction: column;
  }
}

.img-description h2 {
  margin-top: 120px;
  font-size: 25px;
  line-height: 30px;
  color: #565656;
  max-width: 900px;
}

@media (max-width: 991px) {
  .img-description h2 {
    margin-top: 75px;
    font-size: 20px;
    line-height: 26px;
  }
}

@media (max-width: 699px) {
  .oddsee-phones2 {
    max-width: 400px;
  }
}

@media (max-width: 559px) {
  .img-description h2 {
    font-size: 16px;
    line-height: 24px;
  }
}

.guide-user {
  margin-top: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 799px) {
  .oddsee-gallery-header {
    text-align: center;
  }

  .guide-user {
    margin-top: 72px;
    padding-bottom: 40px;
  }
}

@media (max-width: 499px) {
  .text h1.oddsee-gallery-header {
    font-size: 22px;
    line-height: 26px;
  }
}

.guide-user h1 {
  max-width: 670px;
  margin-bottom: 64px;
}

/*.oddsee-swiper-wrapper .swiper {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*}*/

.oddsee-slider {
  max-width: 100vw;
}

/*.oddsee-swiper-wrapper .single-partner {*/
/*  width: 370px;*/
/*}*/

.oddsee-swiper-wrapper .swiper-slide {
  display: flex;
  justify-content: center;
}

/*.oddsee-swiper-wrapper .swiper .swiper-wrapper {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*}*/

.sec-title.white.layout2.oddsee {
  margin-bottom: 60px;
}

@media (max-width: 490px) {
  .sec-title.white.layout2.oddsee {
    margin-bottom: 20px;
  }
}

.single-partner.second {
  margin-bottom: 10px;
}

@media (max-width: 419px) {
  .oddsee-phones {
    max-width: 343px;
  }

  .oddsee-phones2 {
    max-width: 343px;
  }
}

@media (max-width: 369px) {
  .oddsee-phones {
    max-width: 300px;
  }

  .oddsee-phones2 {
    max-width: 300px;
  }

  .img-line img {
    max-width: 300px;
  }
}

@media (max-width: 309px) {
  .oddsee-phones {
    max-width: 270px;
  }

  .oddsee-phones2 {
    max-width: 270px;
  }

  .img-line img {
    max-width: 270px;
  }
}

/*2. WGMI*/

.wgmi .text {
  max-width: 555px;
}

@media (max-width: 911px) {
  .wgmi .text {
    max-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 500px) {
  .wgmi .text {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 500px) {
  .wgmi .text.alt {
    align-items: center;
  }
}

.guide-user.wgmi h1 {
  margin-bottom: 24px;
}

.text ul {
  /*list-style-image: url("../dot3.png");*/
  list-style-type: disc;
}

.text ul li {
  margin-bottom: 25px;
}

.wgmi-gallery {
  height: 1180px;
  margin-bottom: 100px;
}

.wgmi-gallery-wrapper {
  display: flex;
  justify-content: center;
}

@media (max-width: 1369px) {
  .wgmi-gallery {
    flex-direction: column;
    height: auto;
  }

  .wgmi-gallery div {
    gap: 15px;
  }
}

@media (max-width: 1099px) {
  .wgmi-about1,
  .wgmi-about2 {
    max-width: 450px;
  }
}

@media (max-width: 799px) {
  .wgmi-list_container {
    margin-top: 72px;
  }

  .wgmi-shapes {
    margin-bottom: 60px;
  }
}

@media (max-width: 599px) {
  .about-app_alt .text.wgmi-list {
    max-width: 90%;
  }

  .process {
    margin-top: 72px;
  }

  .wgmi-landing-page-img {
    border-radius: 16px;
  }

  .guide-user.wgmi.text {
    padding-bottom: 0;
  }

  .guide-user.wgmi {
    padding-bottom: 0;
  }

  .wgmi-userFlow-img {
    max-width: 94%;
    border-radius: 16px;
  }

  .wgmi-styleGuide-img {
    border-radius: 16px;
  }

  .wgmi-gallery div {
    align-items: center;
  }

  .wgmi-gallery img {
    max-width: 94%;
  }
}

@media (max-width: 500px) {
  .wgmi-about1,
  .wgmi-about2 {
    max-width: 94%;
  }

  .text h1 {
    font-size: 24px;
    line-height: 28px;
  }

  .guide-user-oddsee-landing h1 {
    margin-bottom: 24px;
  }
}

/*3. MTHRBRD*/

.empathy-map {
  display: flex;
  justify-content: center;
}

.protoPersona-wrapper {
  margin-bottom: 216px;
  margin-top: 120px;
}

@media (max-width: 1099px) {
  .mthrbrd-about1,
  .mthrbrd-about2 {
    max-width: 450px;
  }
}

@media (max-width: 500px) {
  .mthrbrd-about1,
  .mthrbrd-about2 {
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  .protoPersona-wrapper {
    margin-top: 72px;
    margin-bottom: 72px;
  }
}

@media (max-width: 699px) {
  .empathy-map {
    overflow: hidden;
  }

  .empathy-map img {
    max-width: 150vw;
  }
}

@media (max-width: 375px) {
  .empathy-map {
    overflow: hidden;
  }

  .empathy-map img {
    max-width: 100%;
  }
}

/*.empathy-map img {*/
/*  width: 100%;*/
/*}*/

.shade-img {
  filter: drop-shadow(0px 555.317px 155.537px rgba(0, 0, 0, 0.01))
    drop-shadow(0px 354.819px 142.171px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 199.282px 120.298px rgba(0, 0, 0, 0.15))
    drop-shadow(0px 88.7049px 88.7049px rgba(0, 0, 0, 0.26))
    drop-shadow(0px 21.8724px 48.6054px rgba(0, 0, 0, 0.29))
    drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.3));
}

.screens img {
  margin-bottom: 144px;
}

.screens img:nth-child(4) {
  margin-bottom: 0;
}

.screens img:nth-child(6) {
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .screens img {
    margin-bottom: 32px;
  }
}

@media (max-width: 550px) {
  .shade-img {
    filter: none;
  }
}

.page-section-offer {
  margin-bottom: 0;
}

@media (max-width: 1499px) {
  .container.wrapper {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 599px) {
  .container.wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 499px) {
  .page-section-offer .title {
    font-size: 30px;
    top: 45%;
  }
}

@media (max-width: 349px) {
  .page-section-offer .title {
    font-size: 22px;
    top: 45%;
  }
}

@media (max-height: 600px) and (orientation: landscape) {
  .page-section-offer .title {
    font-size: 40px;
    top: 45%;
  }
}

@media (max-height: 500px) and (orientation: landscape) {
  .page-section-offer .title {
    font-size: 35px;
    top: 45%;
  }
}

@media (max-height: 399px) and (orientation: landscape) {
  .page-section-offer .title {
    font-size: 30px;
    top: 45%;
  }
}

@media (max-height: 299px) and (orientation: landscape) {
  .page-section-offer .title {
    font-size: 30px;
    top: 45%;
  }
}

@media (max-width: 699px) {

  .page-section-offer .title.ui-ux {
    font-size: 42px;
    top: 40%;
  }
}

@media (max-width: 499px) {

  .page-section-offer .title.ui-ux {
    font-size: 35px;
    top: 40%;
  }
}

@media (max-width: 389px) {

  .page-section-offer .title.ui-ux {
    font-size: 30px;
    top: 40%;
  }
}

@media (max-height: 389px) and (orientation: landscape) {

  .page-section-offer .title.ui-ux {
    font-size: 28px;
    top: 45%;
  }
}

/*4. Metaverse landing page*/

.img-line.landing img {
  max-width: 366px;
}

@media (max-width: 1269px) {
  .img-line.landing img {
    max-width: 343px;
  }
}

@media (max-width: 1199px) {
  .img-line.landing {
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }

  .img-line.landing img {
    max-width: 500px;
  }
}

.img-line.landing {
  margin-top: 120px;
}

@media (max-width: 799px) {
  .img-line.landing {
    margin-top: 56px;
  }

  .process.landing {
    margin-top: 56px;
  }
}

.page-section.landing {
  margin-bottom: 100px;
}

@media (max-width: 799px) {
  .page-section.landing {
    margin-bottom: 50px;
  }
}

@media (max-width: 599px) {
  .img-line.landing {
    gap: 16px;
  }

  .img-line.landing img {
    max-width: 343px;
  }
}

@media (max-width: 399px) {
  .img-line.landing img {
    max-width: 300px;
  }
}

@media (max-width: 310px) {
  .img-line.landing img {
    max-width: 250px;
  }
}

/*Navigation mobile quickfix*/

@media (max-width: 1199px) {
  .main-nav ul li i {
    position: initial;
  }
}

/*Poprawki*/

.oddsee-gallery-header {
  text-align: center;
}

@media (max-width: 599px) {
  .about-app {
    margin-top: 0;
  }

  .img-line.landing img {
    margin: 0;
    max-width: 100%;
  }
}

/*@media (max-width: 911px) {*/
/*  .title-wrap_swiper {*/
/*    max-width: 100%;*/
/*    padding-right: 37px;*/
/*    padding-left: 37px;*/
/*  }*/
/*}*/

@media (max-width: 911px) {
  .sec-title span.case-studies-header {
    font-size: 20px;
  }
}

@media (max-width: 500px) {
  .img-line img {
    max-width: 100%;
  }
}

@media (max-width: 500px) {
  .sec-title span.case-studies-header {
    font-size: 19px;
    line-height: 23px;
  }
}

@media (max-width: 991px) {
  .img-line img {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .img-line.line-showcase img {
    max-width: 100%;
  }

  .img-line.line-showcase {
    gap: 20px;
  }
}

@media (max-width: 899px) {
  .img-line.line-showcase {
    flex-direction: row;
  }
}

@media (max-width: 991px) {
  .about-app .text h1.toLeft,
  .about-app_alt .text h1.toLeft {
    text-align: left;
  }
}

@media (max-width: 500px) {

  .img-line.line-showcase {
    gap: 10px;
  }

  .guide-user.guide-user-oddsee {
    padding-bottom: 0;
  }
}

.landing-intro-background {
  background-image: url("../img/oligamy/case-studies/oddsee/landing-intro/background.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text h1.landing-intro-header {
  color: #fdfdfd;
}

.sec-title.white.layout2.oddsee.oddsee-landing {
  margin-bottom: 70px;
  margin-top: 56px;
}

.error-page-btn {
  width: 210px;
  height: 55px;
  margin-top: 48px;
  background-color: #3fb7e2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #173d46;
  font-weight: 500;
  font-size: 19px;
  transition: 0.8s;
}

.error-page-btn:hover {
  color: #fdfdfd;
}

.error-page-btn svg {
  margin-left: 12px;
  fill: #173d46;
  transition: 0.8s;
}

.error-page-btn:hover svg {
  fill: #fdfdfd;
}

/*Alternative header styles*/

.main-nav.alt ul li a {
  color: #173d46;
}

.main-nav.alt ul li {
  color: #173d46;
}

.header-area.position_top.alt.stick {
  background-color: white;
}

@media (max-width: 1199px) {
  .cross-btn.alt span {
    background: #173d46 !important;
  }
}

/*Design section styles*/

.hero-area.design {
  background-image: url("../img/oligamy/design/headerBg.png");
  background-size: contain;
}

.title-wrap.design {
  max-width: 650px;
}

.cmn-btn.design a {
  font-weight: 500;
}

.sec-title.design-blog {
  text-align: center;
}

.sec-title.design-blog span {
  font-size: 25px;
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.sec-title.design-blog h2 {
  font-size: 45px;
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.sec-title.design-blog p {
  color: #565656;
  margin-top: 16px;
}

.title-wrap.design-blog {
  max-width: none;
}

.single-blog .blog-inner h4 a {
  text-transform: none;
  color: #173d46;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
}

.single-blog .blog-inner .author-date a {
  font-weight: 400;
  color: #878787;
}

.single-blog .blog-inner {
  padding: 0;
  padding-top: 32px;
}

.single-blog .blog-thumb .tag a {
  background-color: #3fb7e2;
  font-weight: 400;
  color: #0e2933;
  font-size: 12px;
}

.single-blog .blog-thumb .tag a:hover {
  color: #fdfdfd;
  background-color: #3fb7e2;
}

.contact-btns.design {
  margin-top: 48px;
}

.contact-btns.design button {
  background-color: #256c86;
  border-radius: 8px;
  padding: 16px 32px;
  font-size: 19px;
  font-weight: 500;
  color: #fdfdfd;
  margin-right: 32px;
}

.sec-title.sec-title_alt.design-blog {
  margin-bottom: 244px;
}

.write-to-us_btn {
  color: #256c86;
  font-size: 19px;
  font-weight: 500;
  border-bottom: 1px solid #256c86;
}

.write-to-us_btn:hover {
  color: #256c86;
}

@media (max-width: 500px) {
  .sec-title_alt {
    margin-bottom: 56px;
  }
}

@media (max-width: 576px) {

  .sec-title.design.white {
    margin-bottom: 56px;
  }
}

@media (max-width: 500px) {
  .sec-title.design-blog {
    margin-bottom: 56px;
  }

  .sec-title.design-blog span {
    font-size: 19px;
    line-height: 23px;
  }

  .sec-title.design-blog h2 {
    font-size: 29px;
  }

  .sec-title.sec-title_alt.design-blog {
    margin-bottom: 196px;
  }
}

.single-blog:hover .blog-thumb a img {
  transform: none;
}

.single-blog .blog-thumb a:hover img {
  transform: scale(1.1);
}

.single-blog .blog-thumb {
  border-radius: 16px;
}

.single-blog .blog-inner h4 a:hover {
  color: #3fb7e2;
}

.write-to-us_btn {
  transition: 0.3s;
}

.write-to-us_btn:hover {
  color: #3fb7e2;
  border-bottom: 1px solid #3fb7e2;
}

.contact-btns.design button {
  transition: 0.5s;
}

.contact-btns.design button:hover {
  color: #173d46;
  background: #3fb7e2;
}

/*Blog subpage styles*/

.breadcrumb-cnt h1 {
  color: #fdfdfd;
  font-weight: 400;
  font-size: 52px;
}

.breadcrumb-cnt span a {
  color: #3fb7e2;
}

.breadcrumbs {
  background-size: cover;
}

.widget-search form button {
  background-color: #3fb7e2;
  width: 56px;
  height: 56px;
  border-radius: 0px 16px 16px 0px;
}

.sidebar-widget {
  padding: 0;
  margin-bottom: 32px;
}

.search-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-search form input {
  background-color: #fdfdfd;
  height: 56px;
  border: 1px solid #d3d3d3;
  border-radius: 16px;
}

.sidebar-widget h4 {
  color: #256c86;
  font-weight: 400;
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 24px;
}

.category li a {
  color: #565656;
}

.category li a:hover {
  color: #3fb7e2;
}

.sidebar-widget {
  padding: 32px 24px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  background-color: #fdfdfd;
}

.sidebar-widget.search {
  padding: 0;
  border: none;
}

.blog-standard {
  margin-bottom: 240px;
  margin-top: 144px;
}

.single-blog.layout4 {
  margin-top: 0;
  background-color: #fdfdfd;
  box-shadow: 0px 0px 16px rgba(7, 21, 26, 0.1);
  border-radius: 16px;
}

.single-blog.layout4:not(:first-child) {
  margin-top: 32px;
}

.single-blog .blog-inner {
  padding: 32px 16px 16px 16px;
}

.single-blog .blog-inner.latest {
  padding: 32px 0px 16px 0px;
}

.paginations li a {
  border: none;
  color: #565656;
  font-weight: 400;
  font-size: 16px;
}

.paginations li a:hover {
  color: #3fb7e2;
  background-color: #fdfdfd;
}

.paginations li.active a {
  background-color: #fdfdfd;
  color: #3fb7e2;
  border: none;
}

.paginations li {
  width: 16px;
  height: 16px;
  margin: 0 8px;
}

.paginations .prev,
.paginations .next {
  border: 1px solid #565656;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  transition: 0.3s;
}

.paginations .prev a,
.paginations .next a {
  width: 25px;
  height: 25px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginations .prev a svg {
  rotate: 180deg;
  width: 16px;
  height: 16px;
  fill: #565656;
}

.paginations .next a svg {
  width: 16px;
  height: 16px;
  fill: #565656;
}

.paginations {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 40px;
}

.load-more {
  display: flex;
  justify-content: center;
}

.paginations li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginations li a:hover {
  background: none;
}

.paginations li a svg {
  transition: 0.3s;
}

.paginations li a:hover svg {
  fill: #3fb7e2;
}

.paginations .prev:hover,
.paginations .next:hover {
  border: 1px solid #3fb7e2;
}

.load-more {
  margin-top: 100px;
}

.breadcrumb-cnt span {
  font-size: 16px;
}

.breadcrumb-cnt h1 {
  margin-bottom: 8px;
}

@media (max-width: 531px) {
  .breadcrumb-cnt {
    margin-top: 50px;
  }

  .breadcrumb-cnt h1 {
    font-size: 39px;
  }
}

/*@media (max-width: 500px) {*/
/*  .single-testimonial.home {*/
/*    padding-left: 20px;*/
/*  }*/
/*}*/

.single-blog .blog-thumb {
  border-radius: 16px 16px 0 0;
}

.single-blog.layout4:hover .blog-thumb a img {
  border-radius: 16px 16px 0 0;
}

.single-blog .blog-thumb a img {
  border-radius: 16px 16px 0 0;
}

@media (max-width: 991px) {
  .sidebar-widget.search {
    margin-top: 56px;
  }
}

@media (max-width: 767px) {
  .sidebar-widget.search {
    margin-top: 0;
  }

  .blog-standard {
    margin-top: 96px;
    margin-bottom: 200px;
  }

  .load-more {
    margin-top: 76px;
  }
}

/*Blog Post styles*/

.blog-details-content img {
  border-radius: 16px;
}

.blog-details-content p {
  color: #565656;
  font-size: 16px;
  line-height: 24px;
}

.tag-share {
  padding: 0 0 0 0;
  margin-top: 56px;
  flex-direction: column;
  border: none;
}

.tag-share a {
  color: #565656;
}

.tag-share span {
  color: #173d46;
  font-weight: 400;
  font-size: 25px;
  margin-bottom: 18px;
}

.share-blog {
  padding: 0 0 0 0;
  border: none;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.blog-details-content h4 {
  color: #173d46;
  font-weight: 400;
  margin-bottom: 16px;
}

.author-date.layout2 a {
  color: #878787;
  font-weight: 400;
  font-size: 16px;
}

.author-date.layout2 {
  margin-bottom: 12px;
}

.blog-details-content p {
  text-align: left;
}

.share-blog svg {
  fill: #878787;
  transition: 0.5s;
}

.share-blog svg:hover {
  fill: #3fb7e2;
}

.social-share-blog li {
  margin: 0;
  margin-right: 30px;
}

@media (max-width: 991px) {
  .blog-details-area .side {
    margin-bottom: 196px;
  }

  .blog-details-content {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .blog-details-area .side {
    margin-top: 58px;
  }
}

.post-content h1 {
  padding-top: 16px;
  font-size: 25px;
  color: #173d46;
  padding-bottom: 16px;
}

.post-content img {
  margin-bottom: 16px;
  margin-top: 16px;
}

.post-content p:not(:last-child) {
  margin-bottom: 16px;
}

.post-content ul {
  list-style-type: disc;
  color: #3fb7e2;
  text-underline-offset: 3px;
}

.post-content ul:not(:last-child) {
  margin-bottom: 32px;
}

.post-content li a:hover {
  color: #3fb7e2;
}
.post-content li:not(:last-child) {
  margin-bottom: 4px;
}

.blog-details-area .side {
  margin-bottom: 246px;
}

.pagination-wrapper.blog {
  justify-content: center;
  margin-top: 100px;
}

@media (max-width: 500px) {
  .pagination-wrapper.blog {
    margin-top: 76px;
  }
}

.pagination.blog {
  color: #565656;
}

.pagination.blog li {
  margin: 8px;
  display: flex;
  align-items: center;
}

.pagination.blog li a {
  transition: 0.5s;
}

.pagination.blog li a:hover {
  color: #3fb7e2;
}

.pagination.blog li.next,
.pagination.blog li.previous {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #565656;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  transition: 0.5s;
}

.pagination.blog li.next:hover,
.pagination.blog li.previous:hover {
  border: 1px solid #3fb7e2;
}

.pagination.blog li.next svg,
.pagination.blog li.previous svg {
  width: 22px;
  height: 22px;
  transition: 0.5s;
}

.pagination.blog li.next:hover svg,
.pagination.blog li.previous:hover svg {
  fill: #3fb7e2;
}

.pagination.blog li.selected {
  color: #3fb7e2;
}

@media (max-width: 991px) {
  /*.single-blog.latest-post {*/
  /*  max-width: 420px;*/
  /*}*/
}

.single-blog .blog-inner .author-date p {
  font-weight: 400;
  font-size: 17px;
  text-transform: capitalize;
  color: #878787;
}

.single-blog .blog-thumb a img {
  max-width: 856px;
  max-height: 319px;
}

.scroll-animation_1 {
  height: 100%;
  /*background-image: url("../img/oligamy/design/headerBg.png");*/
  background-size: contain;
}

/* .animation-container {
  background-image: url("../img/oligamy/design/headerBg.png");
} */

.scroll-animation_1 span {
  color: #0e2933;
  font-size: 128px;
  letter-spacing: -5px;
  display: inline-block;
  max-width: 2000px;
  white-space: nowrap;
  font-weight: 500;
}

@media (max-width: 1099px) {
  .scroll-animation_1 span {
    font-size: 100px;
    max-width: 2000px;
  }
}

@media (max-width: 899px) {
  .scroll-animation_1 span {
    font-size: 85px;
    max-width: 2000px;
  }
}

@media (max-width: 799px) {
  .scroll-animation_1 span {
    font-size: 75px;
    max-width: 2000px;
  }
}

@media (max-width: 649px) {
  .scroll-animation_1 span {
    font-size: 90px;
    max-width: none;
    white-space: normal;
    text-align: center;
    line-height: 100px;
  }
}

@media (max-width: 500px) {
  .scroll-animation_1 span {
    font-size: 70px;
    line-height: 80px;
    letter-spacing: -1px;
    color: #fdfdfd;
  }
}

@media (max-width: 369px) {
  .scroll-animation_1 span {
    font-size: 60px;
    line-height: 70px;
  }
}

@media (max-width: 300px) {
  .scroll-animation_1 span {
    font-size: 50px;
    line-height: 60px;
  }
}

.single-blog .blog-thumb {
  width: 100%;
}

.single-blog .blog-thumb a img {
  width: 100%;
}

/*.single-blog.latest-post {*/
/*  width: 400px;*/
/*}*/

.single-blog .blog-thumb {
  border-radius: 16px;
}

@media (max-width: 991px) {

  .single-blog .blog-inner {
    margin-bottom: 0;
  }
}

.single-blog .blog-thumb a img {
  object-fit: cover;
}

@media (max-width: 1199px) {
  .sec-title h2 {
    font-size: 38px;
  }

  .sec-title.design-blog h2 {
    font-size: 38px;
  }
}

@media (max-width: 500px) {
  .sec-title.design-blog h2 {
    font-size: 29px;
  }

  .sec-title h2 {
    font-size: 29px;
  }

  .blog-details-area .side {
    margin-bottom: 196px;
  }

  .scroll-animation_1 span {
    font-size: 62px;
    line-height: 72px;
  }
}

.design-estimation-banner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
}

.design-estimation-banner h1 {
  color: #fdfdfd;
  font-size: 128px;
  line-height: 110%;
  font-weight: 400;
  text-align: center;
}

.design-estimation-banner button {
  background-color: #3fb7e2;
  border-radius: 8px;
  padding: 16px 32px;
  color: #173d46;
  font-weight: 500;
  font-size: 19px;
  white-space: nowrap;
  transition: 0.8s;
}

.design-estimation-banner button:hover {
  color: #fdfdfd;
}

@media (max-width: 1163px) {
  .design-estimation-banner h1 {
    font-size: 100px;
  }
}

@media (max-width: 931px) {
  .design-estimation-banner h1 {
    font-size: 84px;
  }
}

@media (max-width: 798px) {
  .design-estimation-banner h1 {
    font-size: 70px;
  }
}

@media (max-width: 739px) {
  .design-estimation-banner {
    gap: 40px;
  }
}

@media (max-width: 681px) {
  .design-estimation-banner h1 {
    font-size: 54px;
  }

  .design-estimation-banner {
    gap: 80px;
  }
}

@media (max-width: 500px) {
  .design-estimation-banner h1 {
    font-size: 45px;
  }
}

.single-blog .blog-inner {
  background-color: transparent;
  box-shadow: none;
}

@media (max-width: 991px) {
  .social-share-blog {
    margin-bottom: 56px;
  }
}

@media (max-width: 767px) {
  .social-share-blog {
    margin-bottom: 0;
  }
}

.main-nav ul li ul.sub-menu > li a {
  text-transform: none;
}

.img-line.ui-ux {
  margin-top: 72px;
}

@media (max-width: 991px) {
  .img-line.ui-ux {
    flex-direction: column;
    gap: 50px;
  }
}

.scroll-animation_1 p {
  color: #565656;
  font-size: 45px;
  line-height: 120%;
  max-width: 690px;
  font-weight: 400;
  letter-spacing: -0.45px;
  display: inline;
}

.scroll-animation_1 p span {
  color: #878787;
  font-size: 45px;
  line-height: 120%;
  font-weight: 400;
  letter-spacing: -0.45px;
  display: inline;
  white-space: wrap;
}

.scroll-animation_1.image div {
  margin-top: 0;
}

@media (max-width: 899px) {
  .scroll-animation_1 p {
    font-size: 30px;
    max-width: 500px;
  }

  .scroll-animation_1 p span {
    font-size: 30px;
  }

  .scroll-animation_1 div {
    gap: 50px;
  }
}

@media (max-width: 649px) {
  .scroll-animation_1 p {
    max-width: 500px;
    width: 410px;
  }
}

@media (max-width: 500px) {
  .scroll-animation_1 span {
    color: #0e2933;
  }

  .scroll-animation_1 p {
    width: 100%;
    font-size: 25px;
    max-width: none;
  }

  .scroll-animation_1 p span {
    font-size: 25px;
  }
}

@media (max-width: 420px) {
  .scroll-animation_1 p {
    width: 350px;
  }
}

@media (max-width: 380px) {
  .scroll-animation_1 p {
    width: 30px;
  }
}

.animation-lower {
  position: fixed;
  transform: translate(90%, 250%);
  bottom: 0;
  right: 0;
  width: 700px;
}

@media (max-width: 1299px) {
  .animation-lower {
    transform: translate(80%, 250%);
  }
}

@media (max-width: 1099px) {
  .animation-lower {
    transform: translate(70%, 250%);
    width: 620px;
  }

  .scroll-animation_1 p {
    font-size: 40px;
  }

  .scroll-animation_1 p span {
    font-size: 40px;
  }
}

@media (max-width: 899px) {
  .animation-lower {
    transform: translate(55%, 250%);
    width: 620px;
  }
}

@media (max-width: 739px) {
  .animation-lower {
    transform: translate(55%, 250%);
    width: 600px;
  }

  .scroll-animation_1 p {
    font-size: 38px;
  }

  .scroll-animation_1 p span {
    font-size: 38px;
  }
}

@media (max-width: 659px) {
  .animation-lower {
    transform: translate(50%, 200%);
    width: 500px;
  }

  .scroll-animation_1 p {
    font-size: 38px;
  }

  .scroll-animation_1 p span {
    font-size: 38px;
  }
}

@media (max-width: 649px) {
  .animation-lower {
    transform: translate(50%, 250%);
    width: 450px;
  }

  .scroll-animation_1 p {
    font-size: 35px;
  }

  .scroll-animation_1 p span {
    font-size: 35px;
  }
}

@media (max-width: 500px) {
  .animation-lower {
    transform: translate(50%, 250%);
    width: 400px;
  }

  .scroll-animation_1 p {
    font-size: 25px;
  }

  .scroll-animation_1 p span {
    font-size: 25px;
  }
}

@media (max-width: 420px) {
  .animation-lower {
    transform: translate(55%, 300%);
    width: 360px;
  }
}

@media (max-width: 370px) {
  .animation-lower {
    transform: translate(52%, 300%);
    width: 340px;
  }
}

.scroll-animation_1.image .image-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-animation_1.image .image-wrap.oddsee img {
  max-width: 80%;
}

@media (max-width: 1599px) {
  .scroll-animation_1.image .image-wrap.oddsee img {
    max-width: 90%;
  }

  .scroll-animation_1.image img {
    max-width: 130%;
  }
}

@media (max-width: 1199px) {
  .scroll-animation_1.image .image-wrap.oddsee img {
    max-width: 120%;
  }

  .scroll-animation_1.image img {
    max-width: 180%;
  }
}

@media (max-width: 999px) {
  .scroll-animation_1.image .image-wrap.oddsee img {
    max-width: 150%;
  }

  .scroll-animation_1.image img {
    max-width: 180%;
  }
}

@media (max-width: 420px) {
  .scroll-animation_1 .longer-text .animation-lower {
    transform: translate(50%, 235%);
  }
}

.hero-content h2,
.hero-content p {
  color: #efefef;
  line-height: 120%;
}

.hero-content h2 {
  color: #efefef;
  line-height: 120%;
  font-weight: 400;
}

@media (max-width: 991px) {
  .hero-content p {
    max-width: 600px;
  }
}

@media (max-width: 749px) {
  .hero-content p,
  .hero-content h2 {
    max-width: 500px;
  }
}

@media (max-width: 449px) {
  .hero-content p {
    max-width: 400px;
  }
}

@media (orientation: landscape) and (max-width: 767px) {
  .scroll-animation_1.image .image-wrap.oddsee img {
    max-width: 120%;
  }
}

@media (orientation: landscape) and (max-width: 900px) and (max-height: 500px) {
  .scroll-animation_1.image .image-wrap.oddsee img {
    max-width: 60%;
  }

  .scroll-animation_1.image img {
    max-width: 100%;
  }

  .animation-lower {
    transform: translate(55%, 180%);
  }

  .design-estimation-banner {
    gap: 40px;
    margin-top: 55px;
  }
}

.sec-title.design-blog p.contact-text {
  color: #565656;
}

.header-area.position_top.design.stick .main-nav ul li i {
  color: #fdfdfd;
}

.header-area.position_top.design.stick
  .main-nav
  ul
  li.has-child.active
  .sub-menu
  li
  a {
  color: #173d46;
}

.process.landing img {
  max-width: 65%;
}

@media (max-width: 991px) {
  .process.landing img {
    max-width: 100%;
  }
}

header.design .main-nav ul li:nth-child(2) a.active {
  color: #3fb7e2;
}

.header-area.position_top.design.stick .main-nav.alt ul li:nth-child(3) a {
  color: #3fb7e2;
}

header.design .main-nav ul li:nth-child(2) a {
  color: #3fb7e2;
}
.header-area.position_top.design.stick .main-nav.alt ul li:nth-child(2) a {
  color: #3fb7e2;
}

.header-area.position_top.design
  .main-nav
  ul
  li.has-child.active
  .sub-menu
  li
  a {
  color: #000;
}

@media only screen and (max-width: 1199px) {
  .header-area.position_top.design.stick
    .main-nav
    ul
    li.has-child.active
    .sub-menu
    li
    a {
    color: #565656;
  }

  .header-area.position_top.design
    .main-nav
    ul
    li.has-child.active
    .sub-menu
    li
    a {
    color: #565656;
  }
}

@media (max-width: 1199px) {
  header.design .main-nav ul li:nth-child(2) a.active {
    color: #3fb7e2;
  }
}

@media (max-width: 991px) {
  .hero-content h2 {
    max-width: 600px;
  }
}

@media (max-width: 749px) {
  .hero-content p,
  .hero-content h2 {
    max-width: 500px;
  }
}

@media (max-width: 500px) {
  .hero-content p,
  .hero-content h2 {
    max-width: 350px;
  }
}

@media (max-width: 448px) {
  .sec-title span,
  .sec-title h3 {
    font-size: 19px;
  }
}

.post-content ul {
  margin-left: 20px;
}

.post-content a {
  text-decoration: underline;
  transition: 0.3s;
  color: #3fb7e2;
}

/* Footer Form styles */

.footer-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

footer {
  height: auto;
}

.footer-top {
  padding-bottom: 204px;
}

.footer-form .upper {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.footer-form input[type="text"],
.footer-form textarea {
  background-color: transparent;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  color: #d3d3d3;
}

.input-long textarea {
  height: 128px;
  overflow-x: hidden;
  resize: none;
}

textarea::-webkit-scrollbar {
  display: none;
}

.input-short {
  width: 100%;
}

.input-short p,
.input-long p {
  margin-bottom: 8px;
  color: #d3d3d3;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
}

.data-processing {
  display: flex;
  gap: 40px;
}

.data-processing p {
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: #d3d3d3;
}

.custom-checkbox {
  position: relative;
  cursor: pointer;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border: 1px solid #d3d3d3;
  border-radius: 2px;
}

.custom-checkbox input:checked + .checkmark {
  background-color: #3fb7e2;
  /* Color when checkbox is checked */
  border: 1px solid #3fb7e2;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked + .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.cmn-btn.form {
  margin-top: 32px;
  align-self: flex-end;
  color: #173d46;
}

@media (max-width: 499px) {

  .cmn-btn.form {
    align-self: flex-start;
  }
}

.personal-data.red {
  color: red;
}

.personal-data {
  transition: 0.3s;
}

.footer-form .form-error {
  color: red;
  transition: 0.3s;
  margin-top: 5px;
}

.footer-form .feedback {
  border-radius: 8px;
  padding: 16px;
  display: flex;
  gap: 16px;
  align-items: flex-start;
}

.footer-form .feedback p {
  color: #edf9f4;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.footer-form .feedback svg {
  width: 30px;
  height: 30px;
  min-height: 30px;
  min-width: 30px;
}

.footer-form .feedback.positive {
  background-color: #328514;
}

.footer-form .feedback.negative {
  background-color: #ba1313;
}

.footer-form .feedback.negative p a {
  text-decoration: underline;
  cursor: pointer;
  transition: 0.2s;
}

.footer-form .feedback.negative p a:hover {
  color: #3fb7e2;
}

@media (max-width: 1199px) {
  .footer-form .feedback {
    max-width: 550px;
    align-self: flex-end;
  }
}

@media (max-width: 949px) {
  .footer-form .feedback {
    align-self: center;
  }
}

.copy-txt {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-height: 100%;
  align-items: center;
}

.footer-bottom span.privacy-policy-link a {
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: #efefef;
}

.footer-bottom span.privacy-policy-link a:hover {
  color: #3fb7e2;
}

.privacy-policy {
  margin-top: 190px;
  max-width: 900px;
  margin-bottom: 206px;
}

.privacy-policy h1 {
  color: #0e2933;
  text-align: center;
  font-size: 45px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.45px;
  margin-bottom: 80px;
}

.privacy-policy .paragraph p,
.privacy-policy .paragraph h2,
.privacy-policy .paragraph li {
  color: #565656;
}

.privacy-policy .paragraph:not(:last-child) {
  margin-bottom: 20px;
}

.privacy-policy .paragraph h2 {
  font-size: 25px;
}

.privacy-policy .paragraph p,
.privacy-policy .paragraph li {
  font-size: 16px;
  letter-spacing: -0.25px;
}

.privacy-policy .paragraph ul {
  list-style: disc;
  padding-left: 20px;
}

.privacy-policy .paragraph a {
  color: #3fb7e2;
  text-decoration: underline;
}

@media (max-width: 500px) {
  .privacy-policy {
    margin-top: 154px;
  }

  .privacy-policy h1 {
    margin-bottom: 32px;
    text-align: left;
    font-size: 34px;
  }

  .privacy-policy {
    margin-bottom: 154px;
  }
}

/* Floating form styles */

.floating-form {
  background-color: #173d46;
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 100;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 5px 5px 0px rgba(0, 0, 0, 0.09), 0px 12px 7px 0px rgba(0, 0, 0, 0.05),
    0px 21px 8px 0px rgba(0, 0, 0, 0.01), 0px 33px 9px 0px rgba(0, 0, 0, 0);
  border-radius: 16px;
}

.floating-form .closed,
.floating-form .opened {
  transition: width 0.3s, height 0.3s;
  font-size: 0.3s;
  /* Added transition properties for width and height */
}

.floating-form .closed {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.floating-form .opened {
  width: 375px;
  height: 623px;
  padding: 16px;
  color: #efefef;
}

@media (max-width: 500px) {
  .floating-form {
    bottom: 16px;
    right: 16px;
  }

  .floating-form .opened {
    width: 100vw;
  }
}

.floating-form .opened h2 {
  font-size: 25px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.25px;
}

.floating-form .opened .closeBtn-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.floating-form .opened .closeBtn {
  cursor: pointer;
}

.footer-form.floating {
  margin-top: 16px;
  gap: 0;
}

.footer-form.floating .upper {
  flex-direction: column;
  gap: 0;
}

.footer-form.floating .lower {
  margin-top: 0;
}

.footer-form.floating .input-long textarea {
  height: 90px;
}

.footer-form.floating .cmn-btn.form {
  margin-top: 0;
  width: 100%;
}

.footer-form.floating .floating-form-btn {
  width: 100%;
}

.footer-form.floating .floating-form-btn a {
  background-color: #3fb7e2;
  padding: 16px 32px;
  width: 100%;
  cursor: pointer;
  display: block;
  text-align: center;
  border-radius: 8px;
  color: #173d46;
  font-size: 19px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.19px;
  transition: color 0.5s;
}

.footer-form.floating .floating-form-btn a:hover {
  color: #efefef;
}

.footer-form.floating .data-processing {
  margin-top: 8px;
}

.floating-form-btn {
  margin-top: 16px;
}

.footer-form.floating .form-error {
  margin-bottom: 24px;
  margin-top: 0;
  opacity: 0;
  transition: none;
}

.footer-form.floating .form-error.visible {
  opacity: 1;
  margin-bottom: 0;
}

.floating-form {
  transition: 0.3s;
  z-index: 1001;
}

.white-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 1000;
  opacity: 0.7;
}

@media (max-width: 1000px) and (orientation: landscape) {
  .floating-form {
    bottom: 16px;
    right: 16px;
  }

  .floating-form .opened {
    width: 100vw;
    max-height: 430px;
  }

  .white-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 1000;
    opacity: 0.7;
  }

  .landscape-wrapper .upper {
    max-width: 50%;
  }

  .landscape-wrapper .lower {
    max-width: 50%;
    width: 100%;
  }

  .footer-form.floating .input-long textarea {
    height: 140px;
  }
}

.img-line.billon {
  margin-top: 24px;
}

.billon-preview {
  display: flex;
  justify-content: space-between;
  margin-top: 155px;
}

.billon-preview .right {
  margin-top: 37px;
}

.billon-ipad {
  margin-top: 144px;
  display: flex;
  flex-direction: column;
}

.billon-ipad .upper,
.billon-ipad .lower {
  display: flex;
  max-width: 100%;
}

.billon-ipad .lower {
  align-self: self-end;
}

.billon-ipad .upper img:nth-child(2) {
  margin-left: 40px;
}

.billon-ipad .lower img:nth-child(1) {
  margin-right: 30px;
}

@media (max-width: 1399px) {
  .billon-ipad .upper img:nth-child(2) {
    margin-left: 0;
  }

  .billon-ipad .lower img:nth-child(1) {
    margin-right: 0;
  }
}

.project-challenge {
  background: radial-gradient(
      678.09% 70.71% at 50% 50%,
      #fafafa 25.8%,
      #eef4fb 100%
    ),
    #fafafa;
  padding: 111px 0;
}

.project-challenge h1 {
  color: #2c7789;
  text-align: center;
  font-size: 34px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.34px;
  margin-bottom: 53px;
}

@media (max-width: 500px) {
  .project-challenge h1 {
    font-size: 22px;
  }
}

.project-challenge .tile {
  background-color: #fafafa;
  max-width: 557px;
  box-shadow: 0px 1.90608px 19.06085px 0px #d6ddef;
  border-radius: 15px;
}

.project-challenge .tile.highlighted {
  border: 1px solid #0059ff;
}

.project-challenge .tile h3 {
  color: #256c86;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  text-align: left;
}

.project-challenge .tile p {
  color: #565656;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.project-challenge .tile .top {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  gap: 16px;
  padding-left: 16px;
  align-items: center;
}

.project-challenge .tile .bottom {
  border-top: 1px solid #e1ebfe;
  padding: 14px 22px 22px 53px;
}

.project-challenge .wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.project-challenge .upper {
  display: flex;
  gap: 20px;
}

.project-challenge .lower {
  display: flex;
  gap: 20px;
}

.screens-wrapper.billon {
  display: flex;
  gap: 76px;
}

.screens-wrapper.billon .screens-single-column {
  display: flex;
  flex-direction: column;
  gap: 117px;
}

.img-line.billon {
  justify-content: space-between;
}

@media (max-width: 991px) {
  .billon-preview {
    flex-direction: column;
  }

  .billon-preview .right {
    margin-top: 0;
  }
}

@media (max-width: 899px) {
  .billon-ipad .upper img,
  .billon-ipad .lower img {
    max-width: 50%;
  }
}

@media (max-width: 500px) {
  .billon-ipad .upper img,
  .billon-ipad .lower img {
    max-width: 100%;
  }

  .billon-ipad .upper,
  .billon-ipad .lower {
    flex-direction: column;
  }
}

.project-challenge .upper,
.project-challenge .lower {
  justify-content: center;
}

@media (max-width: 719px) {
  .project-challenge .upper,
  .project-challenge .lower {
    flex-direction: column;
    align-items: center;
  }
}

.about-app.billon img {
  max-width: 440px;
  max-height: 418px;
}

.about-app.billon.filip-puzyr img {
  max-width: 654px;
}

@media (max-width: 991px) {
  .about-app.billon img {
    max-width: 100%;
    max-height: 100%;
  }

  .about-app.billon.filip-puzyr img {
    max-width: 100%;
    max-height: 100%;
  }
}

.about-app.billon2 img {
  max-width: 555px;
  max-height: 444px;
}

@media (max-width: 991px) {
  .about-app.billon2 img {
    max-width: 100%;
    max-height: 100%;
  }

  .screens-wrapper.billon {
    gap: 20px;
  }

  .screens-wrapper.billon .screens-single-column {
    gap: 35px;
  }
}

@media (max-width: 500px) {
  .billon-preview {
    margin-top: 32px;
  }

  .project-challenge {
    padding: 0;
    padding-top: 88px;
    padding-bottom: 40px;
  }
}

.film {
  margin-top: 16px;
}

@media (max-width: 911px) {
  .about-app .text p.billon {
    text-align: left;
  }
}

.footer-widget {
  display: flex;
  flex-direction: column;
}

.footer-widget .socials-wrapper {
  margin-top: 40px;
  max-width: 144px;
}

.footer-widget .socials-wrapper h3 {
  font-size: 25px;
  color: #fdfdfd;
  line-height: 120%;
  letter-spacing: -0.25px;
  text-align: left;
}

.footer-widget .socials-wrapper .socials-buttons {
  display: flex;
  gap: 25px;
  margin-top: 24px;
  align-items: center;
}

.footer-widget .socials-wrapper .socials-buttons a svg path {
  fill: #efefef;
  transition: 0.3s;
}

.footer-widget .socials-wrapper .socials-buttons a:hover svg path {
  fill: #3fb7e2;
}

.floating-estimation {
  background-color: #efefef;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  gap: 48px;
  padding: 24px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05),
    0px 8px 8px 0px rgba(0, 0, 0, 0.04), 0px 19px 11px 0px rgba(0, 0, 0, 0.03),
    0px 33px 13px 0px rgba(0, 0, 0, 0.01), 0px 52px 15px 0px rgba(0, 0, 0, 0);
  border-radius: 16px;
  z-index: 100;
}

.floating-estimation h3 {
  color: #16404f;
  font-family: Saira;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  white-space: nowrap;
}

.floating-estimation h3.small {
  display: none;
}

@media (max-width: 1009px) {
  .floating-estimation h3.big {
    display: none;
  }

  .floating-estimation h3.small {
    display: inline;
  }
}

.floating-estimation button {
  padding: 8px 16px;
  background-color: #3fb7e2;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  color: #173d46;
  white-space: nowrap;
  transition: 0.5s;
}

.floating-estimation button:hover {
  color: #fdfdfd;
}

@media (max-width: 500px) {
  .floating-estimation {
    gap: 10px;
    padding: 14px 14px;
  }
}

/* Product Passport styles */

.passport-tiles {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.passport-tiles .tile {
  width: 100%;
  border-radius: 24px;
  padding: 40px;
}

.passport-tiles .lower {
  display: flex;
  gap: 20px;
}

.passport-tiles .tile h2 {
  color: #fff;
  font-size: 34px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.34px;
  margin-bottom: 32px;
}

.passport-tiles .tile p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.passport-image-gallery {
  display: flex;
  gap: 30px;
}

.passport-image-gallery img {
  box-shadow: 0px 28px 62px 0px rgba(2, 21, 39, 0.05),
    0px 112px 112px 0px rgba(2, 21, 39, 0.04),
    0px 252px 151px 0px rgba(2, 21, 39, 0.03),
    0px 449px 180px 0px rgba(2, 21, 39, 0.01),
    0px 701px 196px 0px rgba(2, 21, 39, 0);
}

.passport-image-gallery .left,
.passport-image-gallery .middle,
.passport-image-gallery .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.passport-image-gallery .left {
  margin-top: 220px;
}

.passport-image-gallery .right {
  margin-top: 131px;
}

.passport-summary {
  margin-top: 110px;
  display: flex;
  justify-content: center;
}

.passport-summary p {
  color: #565656;
  text-align: center;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  max-width: 787px;
}

@media (max-width: 799px) {
  .passport-tiles .lower {
    flex-direction: column;
  }

  .passport-tiles .tile h2 {
    font-size: 22px;
  }
}

@media (max-width: 500px) {
  .passport-image-gallery {
    gap: 8px;
  }

  .passport-image-gallery .left,
  .passport-image-gallery .middle,
  .passport-image-gallery .right {
    gap: 15px;
  }

  .passport-image-gallery .left {
    margin-top: 57px;
  }

  .passport-image-gallery .right {
    margin-top: 34px;
  }

  .passport-summary {
    margin-top: 64px;
  }
}

.film.filip-puzyr video {
  border-radius: 24px;
}

.about-app.filip-puzyr.alt {
  flex-direction: row-reverse;
}

@media (max-width: 991px) {
  .about-app.billon.filip-puzyr {
    flex-direction: column;
  }
}

/* Expectations brief styles */

.progress-bar-border {
  width: 100%;
  height: 20px;
  background-color: #efefef;
  border-radius: 2px;
  position: relative;
}

.progress-bar-proper {
  height: 100%;
  background: linear-gradient(90deg, #3fb7e2 0%, #75dbff 103.38%);
  display: flex;
  padding-right: 5px;
  justify-content: flex-end;
  align-items: center;
  transition: width 0.5s ease;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.brief .lower .right {
  border-left: 1px solid #d3d3d3;
  min-height: 708px;
  width: 460px;
  padding-left: 20px;
  max-width: 460px;
}

@media (max-width: 799px) {
  .brief .lower .right {
    max-width: 100%;
    width: 100%;
    border: none;
    padding-left: 0;
    min-height: 542px;
  }
}

.answers-container .empty-database {
  border-radius: 4px;
  background: linear-gradient(180deg, #efefef 0%, rgba(239, 239, 239, 0) 100%);
  min-height: 492px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.answers-container .empty-database .icon {
  border: 1px solid #a9a9a9;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionnaire-container {
  width: 100%;
}

.questionnaire-title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.questionnaire-title h4 {
  color: #878787;
  font-size: 16px;
  white-space: nowrap;
}

.questionnaire-title .line {
  background-color: #d3d3d3;
  height: 1px;
  width: 100%;
  border: none;
  margin: 0;
}

.questionnaire-section:not(:first-child) {
  margin-top: 8px;
}

.questionnaire-section .options {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.questionnaire-section .option {
  display: flex;
  gap: 8px;
}

.questionnaire-section .option label,
.section .option label {
  font-size: 16px;
  line-height: 150%;
  color: #173d46;
}

.questionnaire-section .option p::first-letter {
  text-transform: uppercase;
}

.questionnaire-container textarea {
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  width: 100%;
  height: 128px;
  resize: none;
  padding: 12px 16px;
}

.questionnaire-container textarea:disabled {
  background-color: #f0f0f0;
}

.questionnaire-container textarea:focus {
  color: #173d46;
}

.questionnaire-container .buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
}

.questionnaire-container .buttons-wrapper button.cancel-btn {
  color: #256c86;
  font-size: 19px;
  font-weight: 500;
  transition: 0.5s;
  white-space: nowrap;
}

.questionnaire-container .buttons-wrapper button.cancel-btn:hover {
  color: #3fb7e2;
}

.questionnaire-container .buttons-wrapper button.next-step-btn {
  background-color: #3fb7e2;
  border-radius: 8px;
  padding: 16px 32px;
  font-size: 19px;
  color: #173d46;
  font-weight: 500;
  transition: 0.5s;
  white-space: nowrap;
}

.questionnaire-container .buttons-wrapper button.next-step-btn:hover {
  color: #fdfdfd;
}

.brief {
  margin-bottom: 236px;
}

/* Reset default checkbox styles */
.brief .custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid transparent;
  /* Add border style as needed */
  outline: none;
  box-shadow: none;
  padding: 0;
  /* Adjust padding as needed */
  margin: 0;
  /* Adjust margin as needed */
  background-color: transparent;
  max-height: 16px;
}

/* Style the custom checkbox */
.brief .custom-checkbox::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  background-color: white;
  vertical-align: middle;
  /* Center the icon vertically */
  margin-bottom: 5px;
}

/* Style the custom checkbox when checked */
.brief .custom-checkbox:checked::before {
  background-color: #3fb7e2;
  border-color: #3fb7e2;
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 20" fill="none"><path d="M6.36641 12.0001L2.56641 8.20007L3.51641 7.25007L6.36641 10.1001L12.4831 3.9834L13.4331 4.9334L6.36641 12.0001Z" fill="white"/></svg>');
}

.second-step .options,
.fourth-step .options {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.second-step .option,
.fourth-step .option {
  display: flex;
  gap: 8px;
}

.second-step .option label,
.fourth-step .option label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.second-step input[type="radio"],
.fourth-step input[type="radio"] {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  padding: 0;
  border: 1px solid var(--radio-border-color);
  border-radius: 50%;
  appearance: none;
  background-color: transparent;
  outline: none;
  margin-right: 8px;
  cursor: pointer;
}

:root {
  --radio-border-color: #d3d3d3;
  --radio-checked-color: #3fb7e2;
  --radio-disabled-bg-color: #d9d9d9;
}

input[type="radio"]:not(:disabled):checked {
  border-color: var(--radio-checked-color);
  background-color: var(--radio-checked-color);
  background-clip: content-box;
  border-width: 3px;
  padding: 2px;
  background-image: radial-gradient(
    circle,
    var(--radio-checked-color) 0%,
    var(--radio-checked-color) 50%,
    transparent 60%,
    transparent 100%
  );
}

input[type="radio"]:disabled {
  background-color: var(--radio-disabled-bg-color);
}

input[type="radio"]:disabled:checked {
  background-image: radial-gradient(
    circle,
    var(--radio-border-color) 0%,
    var(--radio-border-color) 50%,
    transparent 50%,
    transparent 100%
  );
}

.brief button.generate-pdf {
  color: #173d17;
  background-color: #3fb7e2;
  padding: 16px 32px;
  border-radius: 8px;
  font-size: 19px;
  font-weight: 500;
  transition: 0.5s;
  white-space: nowrap;
  max-height: 60px;
}

.brief button.generate-pdf:hover {
  color: #fdfdfd;
}

.brief.summary .lower .header {
  border-bottom: 1px solid #2c7789;
  padding-bottom: 16px;
}

.brief.summary .summary-tile {
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 0px 30px 0px rgba(7, 21, 26, 0.2);
}

.brief.summary .summary-tile .upper {
  background-color: #2c7789;
  border-radius: 16px 16px 0 0;
  height: 52px;
  display: flex;
  align-items: center;
}

.brief.summary .contact-info p {
  color: #2c7789;
}

.brief.summary .contact-info .right {
  padding-left: 0;
  border: none;
  width: auto;
  min-height: auto;
  height: auto;
}

.brief.summary .contact-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
  padding-top: 24px;
  border-top: 1px solid #2c7789;
}

@media (max-width: 500px) {
  .brief.summary .contact-info {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .brief.summary .contact-info p:not(:first-child) {
    margin-top: 16px;
  }
}

.answers-container .answer {
  padding: 16px;
  width: 100%;
  background-color: #efefef;
  border-radius: 8px;
}

.answers-container .answer:not(:first-child) {
  margin-top: 8px;
}

.answer .pen-icon {
  min-width: 24px;
  min-height: 24px;
}

.answers-container .answer .lower .icon {
  width: 16px;
  height: 16px;
  background-color: #878787;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.answers-container {
  max-height: 550px;
  overflow-x: visible;
}
@media (max-width: 799px) {
  .answers-container-mobile {
    max-height: none;
  }
}

.answers-container::-webkit-scrollbar {
  color: #a9a9a9;
  width: 8px;
}

.answers-container::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
  /* Set the color of the thumb (drag handle) */
  border-radius: 100px;
}

.brief.summary .summary-tile .answers .answer .upper {
  background-color: transparent;
}

.brief.summary .summary-tile .answers {
  padding: 0 24px 24px 24px;
}

.brief.summary .answer .upper {
  height: auto;
}

.brief.summary .answer h3 {
  color: #235f6e;
  font-family: Saira;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-align: left;
  margin-bottom: 8px;
}

.brief.summary .answer p {
  color: #565656;
  font-family: Saira;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  overflow: hidden;
}

.summary-tile .lower svg:first-child {
  min-height: 16px;
  min-width: 16px;
}

.brief.summary .answer .icon {
  width: 16px;
  height: 16px;
  background-color: #d3d3d3;
  border-radius: 4px;
}

.brief.summary .answers {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.go-back-btn {
  max-width: 90px;
  cursor: pointer;
  transition: 0.5s;
}

.go-back-btn svg path {
  fill: #6c6c6c;
  transition: 0.5s;
}

.go-back-btn:hover svg path {
  fill: #3fb7e2;
}

.go-back-btn p {
  transition: 0.5s;
}

.go-back-btn:hover p {
  color: #3fb7e2;
}

.abandon-form-background {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.37);
  /* #000 with 37% opacity */
  position: absolute;
}

.abandon-form {
  background-color: #fdfdfd;
  padding: 24px;
  max-width: 554px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
}

.abandon-form p {
  color: #565656;
  font-size: 16px;
  line-height: 150%;
  margin-top: 24px;
}

.abandon-goback {
  color: #256c86;
  /* Component-specific/button */
  font-family: Saira;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 22.8px */
  letter-spacing: -0.19px;
  transition: 0.5s;
  white-space: nowrap;
}

.abandon-goback:hover {
  color: #3fb7e2;
}

button.abandon-cancel {
  border-radius: 8px;
  background: #3fb7e2;
  padding: 16px 32px;
  color: #173d46;
  /* Component-specific/button */
  font-family: Saira;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 22.8px */
  letter-spacing: -0.19px;
  transition: 0.5s;
  white-space: nowrap;
}

button.abandon-cancel:hover {
  color: #fdfdfd;
}

.no-scroll {
  overflow: hidden;
}

.questionnaire-container .first-step .buttons-wrapper {
  justify-content: space-between;
  align-items: center;
}

.links-input-wrapper input {
  width: 100%;
  border-radius: 8px 0px 0px 8px;
  border: 1px solid #d3d3d3;
  padding: 16px;
  color: #878787;
  /* Desktop/Text/body */
  font-family: Saira;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}

.links-input-wrapper input:focus {
  color: #173d46;
}

.links-input-wrapper button.add-link {
  padding: 16px 24px;
  border-radius: 0px 8px 8px 0px;
  background: #3fb7e2;
  color: #173d46;
  transition: 0.5s;
}

.links-input-wrapper button.add-link:hover {
  color: #fdfdfd;
}

.links-wrapper .links {
  margin-top: 8px;
  padding: 16px;
  background-color: #f7f7f7;
  border-radius: 8px;
}

.links-wrapper .links .link {
  display: flex;
  align-items: center;
  gap: 8px;
}

.links-wrapper .links .link a {
  color: #3fb7e2;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Saira;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 150%;
}

.links-wrapper .links .link .bin-icon {
  cursor: pointer;
}

.links-wrapper .links .link .bin-icon svg path {
  fill: #a9a9a9;
  transition: 0.5s;
}

.links-wrapper .links .link .bin-icon:hover svg path {
  fill: #3fb7e2;
}

.brief .error-message {
  display: flex;
  align-items: center;
}

.brief .error-message p {
  color: transparent;
  transition: 0.3s;
}

.brief .error-message.active p {
  color: #ff0000;
}

.links .link svg:first-child {
  min-height: 16px;
  min-width: 16px;
}

.brief .right .answer p {
  overflow: hidden;
}

.brief .right .answer .lower svg:first-child {
  min-height: 16px;
  min-width: 16px;
}

.brief .answers-container-wrapper {
  position: relative;
}

.answers-container-wrapper .answers-container-opacity {
  width: 100%;
  height: 77px;
  background-color: red;
  position: absolute;
  bottom: 0;
  z-index: 20;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fdfdfd 100%);
}

.questionnaire-container .buttons-wrapper.small {
  display: none;
}

@media (max-width: 500px) {
  .questionnaire-container .buttons-wrapper.large {
    display: none;
  }

  .questionnaire-container .buttons-wrapper.small {
    display: flex;
    flex-direction: column;
  }

  .questionnaire-container .buttons-wrapper.small .buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .brief .lower .right {
    min-height: auto;
  }
}

.summary .description a {
  transition: 0.3s;
  text-decoration: underline;
}
.summary a {
  transition: 0.3s;
  text-decoration: underline;
}

.summary a:hover {
  color: #3fb7e2;
}

.fifth-step input[type="text"] {
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  width: 100%;
  height: auto;
  resize: none;
  padding: 12px 16px;
}
.fifth-step input[type="text"] {
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  width: 100%;
  height: auto;
  resize: none;
  padding: 12px 16px;
}

/* About us styles */

.title-list {
  display: flex;
  flex-direction: column;
  gap: 72px;
}

@media (max-width: 500px) {
  .title-list {
    gap: 50px;
  }
}

.title-list .single-point {
  display: flex;
  gap: 56px;
}

.title-list .single-point svg {
  min-width: 100px;
}

.title-list .single-point .item-text h4 {
  color: #0e2933;
  font-family: Saira;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 40.8px */
  letter-spacing: -0.34px;
  margin-bottom: 12px;
}

.title-list .single-point .item-text p {
  color: #565656;
  font-family: Saira;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}

.workflow-section .workflow-tiles h1 {
  color: #3fb7e2;
  font-family: Saira;
  font-size: 95px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 114px */
  letter-spacing: -4.75px;
}

.workflow-section .workflow-tiles h5 {
  color: #0e2933;

  /* Desktop/Text/body */
  font-family: Saira;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
  text-align: left;
  margin-bottom: 16px;
  margin-top: 16px;
}

.workflow-section .workflow-tiles p {
  color: var(--Color-Grey-Colors-600, #6c6c6c);

  /* Desktop/Text/body */
  font-family: Saira;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}

.workflow-section .workflow-tiles .workflow-tile {
  max-width: 361px;
  max-height: 338px;
  padding: 24px;
  border-radius: 16px;
  background-color: #efefef;
}

.workflow-section .workflow-tiles .upper {
  display: flex;
  gap: 24px;
  justify-content: center;
}

.workflow-section .workflow-tiles .lower {
  display: flex;
  gap: 24px;
  justify-content: center;
}

.reference-btn {
  border-radius: 8px;
  background-color: #3fb7e2;
  padding: 16px 32px;
  color: #173d46;
  font-size: 19px;
  font-weight: 500;
  transition: 0.5s;
  white-space: nowrap;
}

.reference-btn:hover {
  color: #fdfdfd;
}

.join-us-section .film-container .film {
  background-color: #000;
  z-index: 10;
  position: relative;
  width: 100%;
  height: 100%;
}

.join-us-section .film-container .film::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(37, 108, 134, 0.7);
  /* Adjust the RGB values for the color #256C86 */
  filter: contrast(200%) brightness(0.5);
  z-index: 10;
}

.join-us-section .film-inner-text {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.swiper.partner-slider.progress-slider .single-partner {
  display: flex;
  flex-direction: column;
  gap: 33px;
}

.swiper.partner-slider.progress-slider .single-partner .upper {
  display: flex;
  width: 100%;
  gap: 24px;
}

.swiper.partner-slider.progress-slider .single-partner .upper .line {
  width: 100%;
}

.swiper.partner-slider.progress-slider .single-partner .upper h3 {
  color: #3fb7e2;
  /* Desktop/Headers/h4 */
  font-family: Saira;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 40.8px */
  letter-spacing: -0.34px;
}

.swiper.partner-slider.progress-slider .single-partner .lower {
  display: flex;
  gap: 50px;
}

.swiper.partner-slider.progress-slider
  .single-partner
  .lower
  .single-timestamp {
  width: 325px;
}

.swiper.partner-slider.progress-slider .single-partner .lower h4 {
  color: #fdfdfd;
  /* Desktop/Headers/h5 */
  font-family: Saira;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.25px;
}

.swiper.partner-slider.progress-slider .single-partner .lower h4 span {
  color: #3fb7e2;
}

.swiper.partner-slider.progress-slider .single-partner .lower p {
  color: #efefef;
  /* Desktop/Text/body */
  font-family: Saira;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
  margin-top: 12px;
}

.oddsee-swiper-wrapper.progress-slider-wrapper .swiper-slide {
  justify-content: flex-start;
}

.swiper.partner-slider.progress-slider .single-partner.single-quote .lower p {
  color: #565656;
  font-family: Saira;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.34px;
}

.swiper.partner-slider.progress-slider .single-partner.single-quote {
  gap: 24px;
}

.swiper.partner-slider.progress-slider .single-partner.single-quote {
  border-right: 2px solid #3fb7e2;
  margin-right: 56px;
  padding-right: 46px;
}

.swiper.partner-slider.progress-slider .single-partner.single-quote.last {
  border-right: none;
  margin-right: 0;
  padding-right: 0;
}

.swiper.partner-slider.progress-slider .single-partner.single-quote .lower {
  display: flex;
  flex-direction: column;
  gap: 88px;
}

.swiper.partner-slider.progress-slider
  .single-partner.single-quote
  .lower
  .author {
  display: flex;
  gap: 16px;
  align-items: center;
}

.swiper.partner-slider.progress-slider
  .single-partner.single-quote
  .lower
  .author
  h5 {
  color: #0e2933;
  font-family: Saira;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 30px */
  letter-spacing: -0.25px;
  text-transform: none;
}

.swiper.partner-slider.progress-slider
  .single-partner.single-quote
  .lower
  .author
  p {
  color: #6c6c6c;

  /* Desktop/Text/body */
  font-family: Saira;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-top: 4px;
}

.swiper.partner-slider.progress-slider
  .single-partner.single-quote
  .lower
  .author
  img {
  max-width: 72px;
  max-height: 72px;
}

.workflow-tile {
  overflow: hidden;
  height: 338px;
  width: 361px;
}

@media (max-width: 500px) {
  .workflow-tile {
    overflow: hidden;
    max-height: 338px;
    min-height: 286px;
    max-width: 361px;
    height: auto;
    width: auto;
  }
}

.workflow-tile .hover-giph {
  opacity: 0;
  overflow: hidden;
  height: 100%;
  margin-top: 50px;
  transition: opacity 0.3s;
}

.workflow-tile .hover-giph img {
  min-height: 100%;
}

.workflow-tile:hover .actual-content {
  display: none;
}

.workflow-tile:hover .hover-giph {
  opacity: 1;
  margin-top: 0;
}

.workflow-tile:hover {
  padding: 0 !important;
}

@media (max-width: 500px) {
  .title-list .single-point {
    flex-direction: column;
    gap: 0;
  }
  .title-list .single-point svg {
    min-width: 0;
    width: 56px;
  }
  .title-list .single-point .item-text h4 {
    font-size: 22px;
  }
  .workflow-section .workflow-tiles h1 {
    font-size: 52px;
  }
  .workflow-tile:hover .hover-giph {
    display: none;
  }
  .workflow-tile:hover .actual-content {
    display: block;
  }
  .workflow-tile {
    padding: 24px;
    min-height: 0;
    transition: 0;
    pointer-events: none;
  }
  .workflow-tile:hover {
    padding: 24px !important;
    min-height: 0;
    pointer-events: none !important;
  }
}

@media (max-width: 1199px) {
  .progress-slider-wrapper .swiper {
    max-width: none;
    margin-left: 0;
  }
  .swiper.partner-slider.progress-slider .single-partner {
    max-width: 100%;
  }
  .swiper.partner-slider.progress-slider
    .single-partner
    .lower
    .single-timestamp {
    width: auto;
  }
}

@media (max-width: 500px) {
  .swiper.partner-slider.progress-slider .single-partner.single-quote {
    margin-right: 0;
  }
  .swiper.partner-slider.progress-slider .single-partner.single-quote .lower p {
    font-size: 22px;
  }
  .swiper.partner-slider.progress-slider
    .single-partner.single-quote
    .lower
    .author
    h5 {
    font-size: 19px;
  }
  .swiper.partner-slider.progress-slider .single-partner.single-quote .lower {
    gap: 72px;
  }
}

/* Career page */

.benefits-section .single-benefit {
  max-width: 510px;
}

.benefits-section .single-benefit h4 {
  color: #0e2933;

  /* Desktop/Headers/h4 */
  font-family: Saira;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 40.8px */
  letter-spacing: -0.34px;
  margin-bottom: 24px;
}

.benefits-section .single-benefit p {
  color: #565656;

  /* Desktop/Text/body */
  font-family: Saira;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.benefits-section .single-benefit .icon {
  position: relative;
  width: 88px;
  height: 80px;
}

.benefits-section .single-benefit .icon svg:first-child {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.benefits-section .single-benefit .icon svg:last-child {
  position: absolute;
  bottom: 0;
  right: 0;
}

.job-list {
  display: flex;
  flex-direction: column;
}

.job-list .single-job-offer {
  background-color: #efefef;
  border-radius: 16px;
  overflow: hidden;
}

.job-list .single-job-offer .lower {
  height: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  transition: 0.5s;
}

.job-list .single-job-offer.extended .lower {
  height: auto;
  padding: 32px 32px 56px 32px !important;
}

.job-list .single-job-offer .upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
}

@media (max-width: 991px) {
  .job-list .single-job-offer .upper {
    align-items: start;
  }
}

.job-list .single-job-offer .upper {
  border-bottom: none;
}

.job-list .single-job-offer.extended .upper {
  border-bottom: 1px solid #d3d3d3;
}

.job-list .single-job-offer .upper .left h3 {
  color: #0e2933;
  text-align: left;

  /* Desktop/Headers/h4 */
  font-family: Saira;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 40.8px */
  letter-spacing: -0.34px;
}

@media (max-width: 767px) {
  .job-list .single-job-offer .upper .left h3 {
    font-size: 22px;
  }

  .job-list .single-job-offer .upper {
    justify-content: space-between;
  }
}

.job-list .single-job-offer .upper .left .tags {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.job-list .single-job-offer .upper .left .tags p {
  color: #565656;

  /* Desktop/Text/body */
  font-family: Saira;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  white-space: nowrap;
}

.job-list .single-job-offer .upper .right svg {
  cursor: pointer;
  transition: 0.5s;
}

.job-list .single-job-offer .upper .right svg {
  rotate: 180deg;
}

.job-list .single-job-offer.extended .upper .right svg {
  rotate: 0deg;
}

.job-list .single-job-offer .lower {
  padding: 32px 32px 56px 32px;
}

button.join-our-team {
  background-color: #256c86;
  border-radius: 8px;
  padding: 16px 32px;
  color: #fdfdfd;
  font-size: 19px;
  white-space: nowrap;
  max-width: 180px;
  align-self: center;
  transition: 0.5s;
}

button.join-our-team:hover {
  background-color: #3fb7e2;
}

@media (max-width: 500px) {
  .job-list .single-job-offer .upper .left h3 {
    font-size: 22px;
  }
  .job-list .single-job-offer .upper .left .tags {
    gap: 8px;
  }
  .job-list .single-job-offer .upper {
    padding: 16px;
    gap: 20px;
  }
  .job-list .single-job-offer.extended .lower {
    padding: 16px 16px 32px 16px !important;
  }
}

.job-offers-section .single-job-offer .lower .job-offer-content h1 {
  color: #0e2933;

  /* Desktop/Headers/h5 */
  font-family: Saira;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 30px */
  letter-spacing: -0.25px;
}

.job-offers-section
  .single-job-offer
  .lower
  .job-offer-content
  h1:not(:first-child) {
  margin-top: 35px;
}

.job-offers-section .single-job-offer .lower .job-offer-content p {
  color: #565656;

  /* Desktop/Text/body */
  font-family: Saira;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-top: 20px;
}

.job-offers-section .single-job-offer .lower .job-offer-content ul {
  list-style-type: disc;
  margin-left: 25px;
  margin-top: 20px;
}

.job-offers-section .single-job-offer .lower .job-offer-content ul li {
  color: #565656;

  /* Desktop/Text/body */
  font-family: Saira;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.job-offers-section .single-job-offer .lower .job-offer-content b {
  font-weight: 600;
}

@media (max-width: 500px) {
  .job-offers-section .single-job-offer .lower .job-offer-content h1 {
    font-size: 21px;
  }
}

/* ------------------Navbar styles------------------- */

.stick {
  background-color: #fdfdfd;
}

.stick .main-nav ul li {
  color: #173d46;
}

.stick .main-nav ul li:hover {
  color: #3fb7e2;
}

.stick .main-nav ul li a {
  color: #173d46;
}

.stick .main-nav ul li a.active {
  color: #3fb7e2;
}

.stick .main-nav ul li:hover a {
  color: #3fb7e2;
}

.header-1_logo path {
  fill: #fdfdfd;
  transition: 0.5s;
}

.header-1_logo path:not(:first-child) {
  stroke: #fdfdfd;
}

.stick .header-1_logo path {
  fill: #173d46;
}

.stick .header-1_logo path:not(:first-child) {
  stroke: #173d46;
}

.header-area.isCollapsed {
  transform: translateY(-100%);
}

.header-area.isShown {
  transform: translateY(0);
}

header .navbar-upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-area {
  flex-direction: column;
}

.navbar-dropdown {
  width: 100%;
  background-color: #fdfdfd;
  border-top: 1px solid #173d46;
  padding: 40px 0;
  -webkit-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
  z-index: -1;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2);
}

.navbar-dropdown ul li {
  font-size: 16px;
  line-height: 24px;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.navbar-dropdown ul li svg path {
  fill: #173d46;
  transition: 0.5s;
}

.navbar-dropdown ul li:hover svg path {
  fill: #3fb7e2;
}

.navbar-dropdown ul li a {
  color: #173d46;
  transition: 0.5s;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-dropdown ul li a.active svg path {
  fill: #3fb7e2;
}

.navbar-dropdown ul li:hover a {
  color: #3fb7e2;
}

.navbar-dropdown ul li a.active {
  color: #3fb7e2;
}

.navbar-dropdown ul li:not(:last-child) {
  border-bottom: 1px solid #173d46;
}

header .navbar-dropdown.dropdown-hidden {
  opacity: 0;
  pointer-events: none;
}

header .navbar-dropdown.dropdown-visible {
  opacity: 1;
}

header.alternative .main-nav ul li a {
  color: #173d46;
}

header.alternative .main-nav ul li:hover a {
  color: #3fb7e2;
}

header.alternative .main-nav ul li a.active {
  color: #3fb7e2;
}

header.alternative .header-1_logo path {
  fill: #173d46;
  transition: 0.5s;
}

header.alternative .header-1_logo path:not(:first-child) {
  stroke: #173d46;
}

@media (max-width: 1199px) {
  header .navbar-upper {
    height: 64px;
  }

  .stick .cross-btn span {
    background: #173d46 !important;
  }
}

.mobile-menu-list {
  display: none;
}

.mobile-blur {
  display: none;
}

@media (max-width: 1199px) {
  .alternative .cross-btn span {
    background: #173d46 !important;
  }

  .mobile-menu-list {
    display: block;
    position: fixed;
    top: 64px;
    left: 0;
    z-index: 14;
    background-color: #fdfdfd;
    height: 100%;
    width: 50%;
    padding: 0 28px;
    transition: 0.5s ease-in-out;
    padding-bottom: 100px;
  }

  .mobile-menu-list .menu-items {
    display: flex;
    flex-direction: column;
  }

  .mobile-menu-list .menu-items a:not(:last-child),
  .mobile-menu-list .menu-items .has-sub-menu:not(:last-child) {
    border-bottom: 1px solid #173d46;
  }

  .mobile-menu-list .menu-items a.active p {
    color: #3fb7e2;
  }

  .mobile-menu-list .menu-items a,
  .mobile-menu-list .menu-items .has-sub-menu {
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .mobile-menu-list .menu-items a p,
  .mobile-menu-list .menu-items .has-sub-menu p {
    color: #173d46;
    font-size: 16px;
    line-height: 24px;
  }

  .mobile-menu-list .menu-items a p,
  .mobile-menu-list .menu-items a svg path,
  .mobile-menu-list .menu-items .has-sub-menu p,
  .mobile-menu-list .menu-items .has-sub-menu svg path {
    transition: 0.5s;
  }

  .mobile-menu-list .menu-items a:hover p,
  .mobile-menu-list .menu-items a:hover svg path,
  .mobile-menu-list .menu-items .has-sub-menu:hover p,
  .mobile-menu-list .menu-items .has-sub-menu:hover svg path {
    color: #3fb7e2;
    fill: #3fb7e2;
  }

  .mobile-menu-list.closed {
    left: -50%;
  }
  .mobile-menu-list.opened {
    left: 0;
  }
  .mobile-blur {
    top: 0;
    left: 0;
    display: block;
    position: fixed;
    transition: 0.5s;
  }
  .mobile-blur.open {
    width: 100%;
    height: 100vh;
    z-index: 13;
    background-color: rgba(253, 253, 253, 0.7); /* #fdfdfd with 50% opacity */
  }
  .mobile-menu-list .back-to-menu {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-top: 16px;
  }

  .mobile-menu-list .back-to-menu p {
    color: #6c6c6c;
  }

  .mobile-menu-list .main {
    padding-top: 56px;
  }

  .mobile-menu-list .sub-menu {
    padding-top: 56px;
  }

  .mobile-menu-list .sub-menu h5 {
    text-align: left;
    color: #878787;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

@media (max-width: 500px) {
  .mobile-menu-list {
    border-top: 1px solid #173d46;
    width: 100%;
    padding: 0 12px;
    padding-bottom: 100px;
  }
  .mobile-menu-list.closed {
    left: -100%;
  }
  .mobile-menu-list.opened {
    left: 0;
    overflow-y: scroll;
  }
}

.header-1_logo {
  width: 126px;
  height: 40px;
}

@media (max-width: 500px) {
  .header-1_logo {
    width: 102px;
    height: 32px;
  }
}

.workflow-tile .hover-giph video {
  min-height: 100%;
  transform: translateX(-21%);
}

/* New mainpage hero area */

.hero-area {
  width: 100%;
  height: 150vh;
  background-color: #16404f;
  position: relative;
  overflow: hidden;
  z-index: -10;
}

.hero-area img {
  position: fixed;
  top: 15%;
  right: 10%;
  display: block;
  z-index: -5;
}

.hero-area .text-container {
  position: fixed;
  bottom: 10%;
  left: 0;
  z-index: 2;
}

@media (max-width: 400px) {
  .hero-area .text-container {
    bottom: 15%;
  }
}

.hero-area .text-container h3 {
  font-size: 45px;
  color: #3fb7e2;
  text-align: left;
  margin-bottom: 24px;
}

.hero-area .text-container h1 {
  text-transform: uppercase;
  font-size: 61px;
  color: #efefef;
  letter-spacing: -3%;
  line-height: 73px;
}

.hero-area .text-container h1 span {
  background-color: #3fb7e2;
  color: #16404f;
}

.hero-area .text-container h1 span.blinking-animation {
  background-color: transparent;
  color: #3fb7e2;
}

.blinking-animation {
  animation: blink-animation 1s step-start infinite;
}

@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}

@media (max-width: 991px) {
  .hero-area .text-container h3 {
    font-size: 38px;
    margin-bottom: 24px;
  }

  .hero-area .text-container h1 {
    font-size: 50px;
    line-height: 61px;
  }
  .hero-area .text-container h1 span {
    line-height: 61px;
  }
}

@media (max-width: 767px) {
  .hero-area .text-container h3 {
    font-size: 35px;
    margin-bottom: 24px;
  }

  .hero-area .text-container h1 {
    font-size: 45px;
  }
  .hero-area .text-container h1 span {
    line-height: 43px;
  }
}

@media (max-width: 500px) {
  .hero-area .text-container h3 {
    font-size: 29px;
    margin-bottom: 24px;
  }

  .hero-area .text-container h1 {
    font-size: 39px;
    line-height: 43px;
  }
  .hero-area .text-container h1 span {
    line-height: 43px;
    display: inline-block;
  }
}

@media (max-width: 500px) {
  .hero-area {
    height: 150vh;
  }
}

/* Navbar expansion styles */

.navbar-dropdown ul.grouped {
  width: 100%;
}

.navbar-dropdown ul.grouped .groups-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns with equal width */
  gap: 32px 56px; /* Gap between grid items */
}

.navbar-dropdown ul.grouped .groups-wrapper .group {
  width: 100%;
}

.navbar-dropdown ul li.group-name {
  border-bottom: none;
  color: #878787;
  font-weight: 600;
}

.mobile-menu-list .group {
  margin-bottom: 40px;
}

.mobile-menu-list .group p.group-title {
  color: #878787;
  margin-top: 15px;
  margin-bottom: 16px;
  font-weight: 500;
}

/* Offer styles */

.offer-quick-nav a {
  color: #173d46;
  transition: color 0.5s ease;
}

.offer-quick-nav a.active {
  color: #3fb7e2;
  font-weight: 600;
  transition: color 5s ease;
}

.offer-quick-nav {
  border-bottom: 1px solid #173d46;
  padding-bottom: 16px;
  position: sticky;
  width: 100%;
}

button {
  transition: 0.5s;
}

.offer-process .line {
  border: 1px solid #a9a9a9;
}

.offer-contact p a {
  color: #3fb7e2;
  transition: 0.5s;
}

.offer-contact p a:hover {
  color: #173d46;
}

.offer-text-image-right {
  gap: 135px;
}

.offer-text-image-left {
  gap: 135px;
}

.offer-title-card .single-row {
  gap: 135px;
}

.offer-title-card .single-row h1 {
  white-space: nowrap;
}

@media (max-width: 1199px) {
  .offer-contact {
    gap: 135px;
  }
  .offer-contact h1 {
    white-space: nowrap;
  }
}

@media (max-width: 991px) {
  .offer-title h1 {
    font-size: 70px;
  }
  .offer-title-card h1 {
    font-size: 50px;
  }
  .offer-process .upper {
    flex-direction: column;
    align-items: start;
    gap: 40px;
  }
  .offer-process .steps-upper {
    grid-template-columns: 1fr 1fr;
  }
  .offer-process .steps-upper .single-step .upper {
    flex-direction: row;
    gap: 16px;
  }
  .offer-blue-text .services-wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .offer-text-image-right {
    flex-direction: column-reverse;
  }

  .offer-text-image-right img {
    max-width: 100%;
  }
  .offer-text-image-right .left {
    max-width: 100%;
  }

  .offer-text-image-left {
    flex-direction: column-reverse !important;
  }
  .offer-text-image-left img {
    max-width: 100%;
  }
  .offer-text-image-left .left {
    max-width: 100%;
  }
  .offer-title-card .single-row {
    flex-direction: column;
    gap: 32px;
  }
}

@media (max-width: 767px) {
  .offer-title h1 {
    font-size: 56px;
  }
  .offer-title-card h1 {
    font-size: 40px;
  }
  .offer-blue-text h4 {
    font-size: 35px;
  }
  .offer-contact {
    flex-direction: column;
    gap: 40px;
  }

  .sample-solutions .solutions-wrapper {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 500px) {
  .offer-title {
    margin-top: 140px !important;
  }
  .offer-title h1 {
    font-size: 39px;
  }
  .offer-title-card {
    margin-top: 50px !important;
  }
  .offer-title-card h1 {
    font-size: 29px;
  }
  .offer-title-card img {
    margin-bottom: 56px;
  }
  .offer-title-card .description {
    margin-top: 32px;
  }
  .offer-blue-text h4 {
    font-size: 34px;
  }
  .offer-process {
    margin-top: 72px !important;
  }
  .offer-process .upper h3 {
    font-size: 29px;
  }
  .offer-blue-text .services-wrapper {
    grid-template-columns: 1fr;
  }
  .offer-blue-text .service-title h2 {
    font-size: 29px;
    font-size: 400;
  }

  .offer-contact h1 {
    font-size: 29px;
  }
  .offer-text-image-right {
    margin-top: 72px !important;
    gap: 48px;
  }
  .offer-text-image-right h2 {
    font-size: 29px;
  }
  .offer-text-image-left {
    gap: 48px;
  }
  .offer-text-image-left h2 {
    font-size: 29px;
  }
  .offer-title-card .single-row {
    gap: 0px;
  }
  .sample-solutions {
    margin-top: 72px !important;
  }
  .sample-solutions h2 {
    font-size: 29px;
  }

  .sample-solutions .solutions-wrapper {
    margin-top: 48px;
  }

  .sample-solutions .solutions-wrapper .single-solution h1 {
    font-size: 61px;
  }
}

.offer-quick-nav {
  transition: all 0.5s;
  border-bottom: none;
  background-color: #fdfdfd;
  top: 0;
  z-index: 10;
}

.offer-quick-nav.offset {
  top: 70px;
}

@media (max-width: 1200px) {
  .offer-quick-nav.offset {
    top: 50px;
  }
}

.offer-quick-nav .container {
  border-bottom: 1px solid #173d46;
  padding-bottom: 16px;
  display: flex;
  gap: 24px;
}

@media (max-width: 500px) {
  .offer-quick-nav .container {
    overflow-y: scroll; /* Always show vertical scrollbar (optional, adjust as needed) */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Chrome, Safari, Opera */
  }

  .offer-quick-nav a {
    white-space: nowrap;
  }
}

@media (max-width: 1000px) {
  .offer-quick-nav-special .container {
    overflow-y: scroll; /* Always show vertical scrollbar (optional, adjust as needed) */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Chrome, Safari, Opera */
  }
  .offer-quick-nav-special a {
    white-space: nowrap;
  }
}
/*.offer-quick-nav.isFixed {*/
/*  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);*/
/*}*/
@media (max-width: 500px) {
  .blog-slider {
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    width: 100%;
    display: flex;
  }

  /* Hide scrollbar for Webkit-based browsers (Chrome, Safari, Opera) */
  .blog-slider::-webkit-scrollbar {
    display: none;
  }

  .blog-slider div {
    white-space: nowrap;
  }
}
@media (min-width: 500px) {
  .blog-slider {
    display: flex;
    flex-wrap: wrap;
  }
}
/*.offer-quick-nav.isFixed {*/
/*  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);*/
/*}*/

.offer-quick-nav.isFixed .container {
  border-bottom: none;
  padding-bottom: 6px;
}

/* Contact page styles */

.contact-page .contact-info ul a {
  transition: 0.5s;
}

.contact-page .contact-info ul a:hover {
  color: #3fb7e2;
}

.contact-page .contact-info .socials-icons svg path {
  transition: 0.5s;
  fill: #878787;
}

.contact-page .contact-info .socials-icons svg:hover path {
  fill: #3fb7e2;
}

.contact-page .offer-download_btn a:hover {
  color: #173d46;
  border-bottom: 1px solid #173d46;
}

.contact-page .input-short p,
.contact-page .input-long p {
  color: #173d46;
}

.contact-page .data-processing p {
  color: #173d46;
}

.contact-page .footer-form input[type="text"],
.contact-page .footer-form textarea {
  color: #173d46;
}

.contact-page .cmn-btn a {
  transition: 0.5s;
  background-color: #3fb7e2;
}

.contact-page .cmn-btn a:hover {
  color: #fdfdfd;
}

.contact-page .footer-form .form-error {
  color: red;
}

.contact-page .personal-data.red {
  color: red;
}

.contact-page .nda-option p {
  color: #173d46;
}

.contact-page .nda-option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contact-page .nda h5 {
  text-align: left;
  color: #173d46;
  text-transform: none;
  margin-bottom: 16px;
}

.contact-page .nda-option input[type="radio"] {
  /* Custom styles for the radio buttons */
  width: 16px; /* Set the width */
  height: 16px; /* Set the height */
}
.nda-option input[type="radio"] {
  /* Hide the default radio button */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px; /* Set the width of the custom radio button */
  height: 20px; /* Set the height of the custom radio button */
  border-radius: 50%; /* Make it round */
  border: 2px solid #ccc; /* Border color for the custom radio button */
  outline: none; /* Remove outline */
  position: relative;
  cursor: pointer;
}

/* Custom styles for checked radio buttons */
.nda-option input[type="radio"]:checked::before {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 12px; /* Size of the inner circle */
  height: 12px; /* Size of the inner circle */
  border-radius: 50%; /* Make it round */
}

.footer-form.floating .nda h5 {
  font-size: 16px;
  color: #d3d3d3;
  text-align: left;
  text-transform: none;
}

.footer-form.floating .nda .nda-option {
  display: flex;
  align-items: center;
  gap: 5px;
}

.footer-form.floating .data-processing {
  margin-top: 30px;
}

@media (max-width: 1000px) and (orientation: landscape) {
  .floating-form .opened {
    max-height: 410px;
  }
}

@media (max-width: 500px) {
  .contact-page .footer-form .upper {
    flex-direction: column;
  }
}

/*Blog tags styles*/
@media (max-width: 500px) {
  .contact-page .footer-form .upper {
    flex-direction: column;
  }
}

.single-tag {
  border: 1px solid #d3d3d3;
  border-radius: 100px;
  padding: 8px 16px;
  cursor: pointer;
  color: #173d46;
  font-size: 16px;
  transition: 0.5s;
  background-color: #fdfdfd;
}

.single-tag:hover {
  background-color: rgba(63, 183, 226, 0.8);
}

.single-tag.active {
  background-color: #3fb7e2;
  color: #0e2933;
  border: none;
}

.blog-standard {
  margin-top: 72px;
}

@media (max-width: 500px) {
  .blog-standard {
    margin-top: 56px;
  }
}

.blog-standard-details {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 56px;
}

@media (max-width: 1199px) {
  .blog-standard-details {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 991px) {
  .blog-standard-details {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 500px) {
  .blog-standard-details {
    row-gap: 48px;
  }
}

.blog-thumb {
  height: 278px;
}

.blog-thumb img {
  height: 100%;
}

.single-blog.layout4 {
  box-shadow: none;
  background-color: transparent;
}

.single-blog .blog-inner {
  padding: 0;
  padding-top: 32px;
}

.single-blog .blog-inner .author-date p {
  font-size: 16px;
}

.single-blog .blog-inner .author-date p:first-child {
  color: #3fb7e2;
  text-transform: uppercase;
}

/*Human approach section*/

.human-approach-section .single-message {
  display: flex;
  gap: 20px;
  align-items: end;
}

.human-approach-section .single-message:nth-child(even) {
  align-self: end;
  flex-direction: row-reverse;
}

.human-approach-section .single-message p {
  color: #173d46;
  font-size: 25px;
  line-height: 35px;
  max-width: 670px;
}

.human-approach-section .single-message p span {
  font-weight: 500;
  color: #173d46;
}

.human-approach-section .single-message .avatar-container img {
  width: 95px;
  height: auto;
}

@media (max-width: 1200px) {
  .human-approach-section .single-message .avatar-container img {
    width: 95px;
  }
  .human-approach-section .single-message .avatar-container {
    min-width: 95px;
  }
}

.human-approach-section .single-message .message-container {
  background-color: #fdfdfd;
  padding: 32px;
  border-radius: 32px 32px 32px 0;
  box-shadow: 7px 7px 6px 0 #00000008;
}

.human-approach-section .single-message:nth-child(even) .message-container {
  border-radius: 32px 32px 0 32px;
}

@media (max-width: 400px) {
  .human-approach-section .messages-wrapper {
    min-height: 460px;
  }
}

@media (max-width: 500px) {
  .human-approach-section .messages-wrapper {
    min-height: 350px;
  }
}
@media (max-width: 767px) {
  .human-approach-section .single-message p {
    font-size: 20px;
    line-height: 22.4px;
  }
  .human-approach-section .single-message p {
    max-width: 100%;
  }

  .human-approach-section .single-message .avatar-container img {
    width: 50px;
  }

  .human-approach-section .single-message .avatar-container {
    min-width: 50px;
  }
}

.human-approach-section .messages-wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

@media (max-width: 767px) {
  .human-approach-section .messages-wrapper {
    gap: 16px;
  }
}

@media (max-width: 500px) {
  .human-approach-section .single-message p {
    font-size: 16px;
    line-height: 22.4px;
  }

  .human-approach-section .single-message .avatar-container img {
    width: 24px;
  }

  .human-approach-section .single-message .avatar-container {
    min-width: 24px;
  }

  .human-approach-section .single-message {
    gap: 8px;
  }

  .human-approach-section .single-message .message-container {
    margin-right: 32px;
    padding: 24px;
  }

  .human-approach-section .single-message .message-container.writing {
    padding: 18px;
  }

  .human-approach-section .single-message:nth-child(even) .message-container {
    margin-left: 32px;
    margin-right: 0;
  }
}

button.primary-button {
  background-color: #3fb7e2;
  padding: 16px 32px;
  border-radius: 8px;
  font-size: 19px;
  font-weight: 500;
  color: #173d46;
  cursor: pointer;
  transition: 0.5s;
  line-height: 22.8px;
}
button.primary-button:hover {
  color: #fdfdfd;
}

.case-studies-home-swiper .img-wrapper {
  overflow: hidden;
}

.case-studies-home-swiper .img-wrapper img {
  transition: 0.5s;
}

.case-studies-home-swiper .img-wrapper:hover img {
  scale: 1.1;
}

.case-studies-home-swiper .swiper {
  max-width: 100%;
  margin-left: 0;
}

.in-the-news-section .news-container .single-news {
  padding-top: 56px;
}

@media (max-width: 500px) {
  .in-the-news-section .news-container .single-news {
    padding-top: 40px;
  }
}

.in-the-news-section .news-container .single-news {
  transition: 0.5s;
}

.in-the-news-section .news-container .single-news:hover {
  background-color: #173d46;
}

.in-the-news-section .news-container .single-news p {
  transition: 0.5s;
}

.in-the-news-section .news-container .single-news svg path {
  transition: 0.5s;
  fill: #0e2933;
}

.in-the-news-section .news-container .single-news:hover svg path {
  fill: #fdfdfd;
}

.in-the-news-section .news-container .single-news:hover p {
  color: #fdfdfd;
}

.offer-process .single-step:last-child .line {
  display: none;
}

@media (max-width: 991px) {
  .offer-process .single-step:last-child .line {
    display: block;
  }
}

.case-studies-home-swiper .single-slide p {
  transition: 0.5s;
}

.case-studies-home-swiper .single-slide:hover p {
  color: #3fb7e2;
}

.case-studies-home-swiper .single-slide svg path {
  fill: #0e2933;
  transition: 0.5s;
}

.case-studies-home-swiper .single-slide:hover svg path {
  fill: #3fb7e2;
}

.human-approach-section .message-container .writing-animation {
  display: flex;
  gap: 8px;
  position: relative;
}

.human-approach-section .message-container .writing-animation .dot {
  width: 16px;
  height: 16px;
  background-color: #efefef;
  border-radius: 50%;
}
@media (max-width: 500px) {
  .human-approach-section .message-container .writing-animation .dot {
    width: 8px;
    height: 8px;
    background-color: #efefef;
    border-radius: 50%;
  }
}
.human-approach-section .message-container .writing-animation .highlighted-dot {
  width: 20px;
  height: 20px;
  background-color: #a9a9a9;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  animation: jumpDot 0.7s infinite; /* Adjust duration as needed */
}
@media (max-width: 500px) {
  .human-approach-section
    .message-container
    .writing-animation
    .highlighted-dot {
    width: 10px;
    height: 10px;
    background-color: #a9a9a9;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    animation: jumpDot 0.7s infinite; /* Adjust duration as needed */
  }
}
@keyframes jumpDot {
  0%,
  100% {
    left: 0;
    transform: translate(0, -50%);
  }
  25% {
    left: 50%; /* Jump to the middle */
    transform: translate(-50%, -50%);
  }
  50% {
    left: 100%; /* Jump to the right edge */
    transform: translate(-100%, -50%);
  }
  75% {
    left: 0; /* Jump back to the middle */
    transform: translate(0, -50%);
  }
}

.human-approach-section
  .messages-wrapper
  .single-message:nth-child(even)
  .message-container
  .writing-animation
  .highlighted-dot {
  width: 20px;
  height: 20px;
  background-color: #a9a9a9;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  animation: jumpDotAlt 0.7s infinite; /* Adjust duration as needed */
}
@media (max-width: 500px) {
  .human-approach-section
    .messages-wrapper
    .single-message:nth-child(even)
    .message-container
    .writing-animation
    .highlighted-dot {
    width: 10px;
    height: 10px;
    background-color: #a9a9a9;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    animation: jumpDotAlt 0.7s infinite; /* Adjust duration as needed */
  }
}
@keyframes jumpDotAlt {
  0%,
  100% {
    left: 100%;
    transform: translate(-100%, -50%);
  }
  25% {
    left: 50%; /* Jump to the middle */
    transform: translate(-50%, -50%);
  }
  50% {
    left: 0; /* Jump to the right edge */
    transform: translate(0, -50%);
  }
  75% {
    left: 100%; /* Jump back to the middle */
    transform: translate(-100%, -50%);
  }
}

.palto-text-list li {
  margin-left: 25px;
  margin-bottom: 2px !important;
}
@media (max-width: 500px) {
  .palto-text-list li {
    margin-left: 30px;
    margin-bottom: 2px !important;
  }
}

.solutions .solutions-row h4 {
  font-size: 34px;
}
@media (max-width: 500px) {
  .solutions .solutions-row h4 {
    font-size: 22px;
  }
}
.dredging-text-list {
  list-style: none !important;
}
.dredging-text-list li {
  margin-left: 4px;
}

.oversite-web-about-margin {
  margin-bottom: 4px !important;
}
.svg-design {
  fill: #fdfdfd !important;
}

.post-content p a:hover {
  color: #1f97c2 !important;
}

.post-content ul {
  color: #565656;
}

.post-content h1 a:hover {
  color: #565656;
}

a.primary-link {
  background-color: #3fb7e2;
  padding: 16px 32px;
  border-radius: 8px;
  font-size: 19px;
  font-weight: 500;
  color: #173d46;
  cursor: pointer;
  transition: 0.5s;
  line-height: 22.8px;
}
a.primary-link:hover {
  color: #fdfdfd;
}
.design-news:hover {
  background-color: #235f6e !important;
  color: #3fb7e2 !important;
}

.design-news:hover .text-default {
  color: #3fb7e2 !important;
}

.design-news:hover .svg-design {
  fill: #3fb7e2 !important;
}

.summary a.go-back-link {
  text-decoration: none;
}

.summary a.underlineless {
  text-decoration: none;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .swiper-margin {
    margin-right: 130px !important;
  }
}

.continuous-slide {
  overflow: hidden;
}

.swiper-slide {
  transform: translate3d(0, 0, 0);
  will-change: transform;
}

.film {
  overflow: hidden;
}

.img-line {
  overflow-x: hidden;
}

.animatedform::after,
.animatedform::before {
  content: "";
  position: absolute;
  border-radius: 16px;
  background-color: #173d46;
  opacity: 0.6;
  transform: scale(0.75);
  animation: scaleUp 2s ease-in-out alternate;
  animation-iteration-count: 6;
  z-index: -1;
}

.animatedform::after {
  left: -15%;
  width: 90px;
  height: 90px;
  opacity: 0.7;
}

.animatedform::before {
  left: -7%;
  width: 80px;
  height: 80px;
  opacity: 0.6;
}

@keyframes scaleUp {
  from {
    transform: scale(0.75);
  }
  to {
    transform: scale(1);
  }
}
@keyframes shake {
  0% {
    transform: translateX(0) rotate(0deg);
  }
  25% {
    transform: translateX(2px) rotate(10deg);
  }
  50% {
    transform: translateX(0) rotate(0deg);
  }
  75% {
    transform: translateX(-2px) rotate(-10deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}

.shaking {
  animation: shake 1s ease-in-out;
  animation-iteration-count: 12;
}

.licenses-link {
  color: #565656 !important;
}
