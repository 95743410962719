/* global.css */
@tailwind base;
@layer base {
  img {
    @apply inline-block;
  }
}
@tailwind components;
@tailwind utilities;

@import url("./assets/css/swiper-bundle.min.css");
@import url("./assets/css/bootstrap.min.css");
@import url("./assets/sass/style.css");
