@import url("./assets/css/swiper-bundle.min.css");
@import url("./assets/css/bootstrap.min.css");
@import url("./assets/sass/style.css");

.swiper-slide {
  width: 100% !important;
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1306px,
);
